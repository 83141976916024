export const SvgPhoneFilled = (props) => {
    const { width, height, color } = props;

    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M23,11a1,1,0,0,1-1-1,8.008,8.008,0,0,0-8-8,1,1,0,0,1,0-2A10.011,10.011,0,0,1,24,10,1,1,0,0,1,23,11Zm-3-1a6,6,0,0,0-6-6,1,1,0,1,0,0,2,4,4,0,0,1,4,4,1,1,0,0,0,2,0Zm2.183,12.164.91-1.049a3.1,3.1,0,0,0,0-4.377c-.031-.031-2.437-1.882-2.437-1.882a3.1,3.1,0,0,0-4.281.006l-1.906,1.606A12.784,12.784,0,0,1,7.537,9.524l1.6-1.9a3.1,3.1,0,0,0,.007-4.282S7.291.939,7.26.908A3.082,3.082,0,0,0,2.934.862l-1.15,1C-5.01,9.744,9.62,24.261,17.762,24A6.155,6.155,0,0,0,22.183,22.164Z" /></svg>
}

export const SvgVideoFilled = (props) => {
    const { width, height, color } = props;

    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M5,20H13c2.757,0,5-2.243,5-5v-6c0-2.757-2.243-5-5-5H5C2.243,4,0,6.243,0,9v6C0,17.757,2.243,20,5,20ZM24,8.313v7.319c0,.757-.42,1.437-1.097,1.775-.283,.141-.587,.211-.889,.211-.421,0-.837-.135-1.189-.399-.037-.028-.825-.81-.825-.81V7.544s.786-.789,.825-.818c.604-.454,1.399-.527,2.078-.188s1.097,1.018,1.097,1.775Z" /></svg>
}

export const SvgPaperSendFilled = (props) => {
    const { width, height, color } = props;

    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="m.172,3.708C-.216,2.646.076,1.47.917.713,1.756-.041,2.951-.211,3.965.282l18.09,8.444c.97.454,1.664,1.283,1.945,2.273H4.048L.229,3.835c-.021-.041-.04-.084-.057-.127Zm3.89,9.292L.309,20.175c-.021.04-.039.08-.054.122-.387,1.063-.092,2.237.749,2.993.521.467,1.179.708,1.841.708.409,0,.819-.092,1.201-.279l18.011-8.438c.973-.456,1.666-1.288,1.945-2.28H4.062Z" /></svg>
}

export const SvgPaperClipFilled = (props) => {
    const { width, height, color } = props;

    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M19,4v13c0,3.86-3.14,7-7,7s-7-3.14-7-7V5C5,2.24,7.24,0,10,0s5,2.24,5,5v12c0,1.65-1.35,3-3,3s-3-1.35-3-3V6c0-.55,.45-1,1-1s1,.45,1,1v11c0,.55,.45,1,1,1s1-.45,1-1V5c0-1.65-1.35-3-3-3s-3,1.35-3,3v12c0,2.76,2.24,5,5,5s5-2.24,5-5V4c0-.55,.45-1,1-1s1,.45,1,1Z" /></svg>
}

export const SvgDoubleCheckFilled = (props) => {
    const { width, height, color } = props;

    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="m1.283,7.697c-.385-.396-.375-1.029.021-1.414.396-.385,1.03-.376,1.414.021l4.089,4.211c.307.31.729.485,1.176.486.445,0,.864-.173,1.179-.488L18.29,1.296c.388-.394,1.021-.396,1.414-.007.393.389.396,1.022.007,1.414l-9.131,9.219c-.696.696-1.624,1.078-2.604,1.078-.982-.002-1.904-.387-2.596-1.085L1.283,7.697Zm22.423-.405c-.391-.391-1.025-.389-1.414.002l-13.087,13.12c-.378.378-.884.586-1.418.586-.536,0-1.039-.212-1.423-.599L1.699,15.784c-.394-.388-1.026-.386-1.415.008-.388.393-.385,1.025.007,1.414l4.659,4.61c.755.761,1.761,1.181,2.833,1.184,1.068,0,2.081-.416,2.837-1.173l13.088-13.121c.39-.391.389-1.024-.002-1.414Z" /></svg>

}

export const SvgMsgIconFilled = (props) => {
    const { width, height, color } = props;

    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M8.7,18H3c-1.493,0-3-1.134-3-3.666V9.294A9.418,9.418,0,0,1,8.349.023a9,9,0,0,1,9.628,9.628A9.419,9.419,0,0,1,8.7,18ZM20,9.08h-.012c0,.237,0,.474-.012.712C19.59,15.2,14.647,19.778,9.084,19.981l0,.015A8,8,0,0,0,16,24h5a3,3,0,0,0,3-3V16A8,8,0,0,0,20,9.08Z" /></svg>

}


export const SvgMsgCloudIconFilled = (props) => {
    const { width, height, color } = props;

    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M3,22.5c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5,.672-1.5,1.5-1.5,1.5,.672,1.5,1.5Zm3-5.5c-1.105,0-2,.895-2,2s.895,2,2,2,2-.895,2-2-.895-2-2-2Zm9.845-1.42c.699,.279,1.422,.42,2.155,.42,3.309,0,6-2.691,6-6,0-2.733-1.823-5.069-4.416-5.772-.938-2.518-3.356-4.228-6.084-4.228-1.879,0-3.652,.819-4.88,2.223-.524-.147-1.067-.223-1.62-.223C3.691,2,1,4.691,1,8c0,3.242,2.585,5.892,5.802,5.997,1.062,1.845,3.032,3.003,5.198,3.003,1.426,0,2.767-.499,3.845-1.42Z" /></svg>
}


export const SvgArrowLeftFilled = (props) => {
    const { width, height, color } = props;

    return <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M19,11H9l3.29-3.29a1,1,0,0,0,0-1.42,1,1,0,0,0-1.41,0l-4.29,4.3A2,2,0,0,0,6,12H6a2,2,0,0,0,.59,1.4l4.29,4.3a1,1,0,1,0,1.41-1.42L9,13H19a1,1,0,0,0,0-2Z" /></svg>
}

export const SvgOrderOutlined = (props) => {
    const { width, height, color } = props;

    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" id="package"><rect width={width} height={height} fill={color}></rect><path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" d="M224,177.32122V78.67878a8,8,0,0,0-4.07791-6.9726l-88-49.5a8,8,0,0,0-7.84418,0l-88,49.5A8,8,0,0,0,32,78.67878v98.64244a8,8,0,0,0,4.07791,6.9726l88,49.5a8,8,0,0,0,7.84418,0l88-49.5A8,8,0,0,0,224,177.32122Z"></path><polyline fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" points="177.022 152.511 177.022 100.511 80 47"></polyline><polyline fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" points="222.897 74.627 128.949 128 33.108 74.617"></polyline><line x1="128.949" x2="128.01" y1="128" y2="234.821" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line></svg>
}

export const SvgInviceOutlined = (props) => {
    const { width, height, color } = props;

    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M17,16.5v1c0,.828-.672,1.5-1.5,1.5h-7c-.828,0-1.5-.672-1.5-1.5v-1c0-.828,.672-1.5,1.5-1.5h7c.828,0,1.5,.672,1.5,1.5Zm-8.5-3.5h2c.829,0,1.5-.671,1.5-1.5s-.671-1.5-1.5-1.5h-2c-.829,0-1.5,.671-1.5,1.5s.671,1.5,1.5,1.5Zm0-5h2c.829,0,1.5-.671,1.5-1.5s-.671-1.5-1.5-1.5h-2c-.829,0-1.5,.671-1.5,1.5s.671,1.5,1.5,1.5Zm13.5,.157v10.343c0,3.033-2.467,5.5-5.5,5.5H7.5c-3.033,0-5.5-2.467-5.5-5.5V5.5C2,2.467,4.467,0,7.5,0h6.343c1.469,0,2.85,.572,3.889,1.611l2.657,2.657c1.039,1.039,1.611,2.419,1.611,3.889Zm-3,10.343V9h-4c-1.105,0-2-.895-2-2V3H7.5c-1.378,0-2.5,1.122-2.5,2.5v13c0,1.378,1.122,2.5,2.5,2.5h9c1.378,0,2.5-1.122,2.5-2.5Z" /></svg>
}


export const SvgCheckCircleFill = (props) => {
    const { width, height, color } = props;

    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M24,12c0-1.626-.714-3.16-1.925-4.124,.14-1.622-.44-3.211-1.59-4.362-1.15-1.149-2.735-1.728-4.277-1.555-2.014-2.556-6.365-2.604-8.332-.035-1.624-.144-3.211,.439-4.361,1.59-1.149,1.15-1.729,2.74-1.555,4.277-2.556,2.014-2.605,6.365-.035,8.333-.14,1.622,.44,3.211,1.59,4.362,1.15,1.149,2.737,1.73,4.277,1.555,2.014,2.556,6.365,2.604,8.332,.035,1.62,.136,3.21-.439,4.361-1.59,1.149-1.15,1.729-2.74,1.555-4.277,1.246-1.048,1.96-2.582,1.96-4.208Zm-6.46-1.434l-4.739,4.568c-1.163,1.161-3.066,1.151-4.229-.013l-2.252-2.092c-.404-.376-.428-1.009-.052-1.413,.377-.405,1.011-.427,1.413-.052l2.278,2.117c.418,.417,1.05,.416,1.44,.025l4.752-4.581c.398-.382,1.031-.371,1.414,.026,.384,.397,.372,1.031-.025,1.414Z" /></svg>
}

export const SvgExclamationMarkFill = (props) => {
    const { width, height, color } = props;

    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="m23.34,9.48l-3.5-6c-.893-1.53-2.547-2.48-4.319-2.48h-7.072c-1.771,0-3.426.95-4.319,2.48L.631,9.48c-.907,1.554-.907,3.485,0,5.039l3.5,6c.893,1.53,2.547,2.48,4.319,2.48h7.072c1.771,0,3.426-.95,4.319-2.48l3.5-6c.907-1.554.907-3.485,0-5.039Zm-12.34-2.48c0-.553.448-1,1-1s1,.447,1,1v5.5c0,.553-.448,1-1,1s-1-.447-1-1v-5.5Zm1,11c-.828,0-1.5-.672-1.5-1.5s.672-1.5,1.5-1.5,1.5.672,1.5,1.5-.672,1.5-1.5,1.5Z" /></svg>
}

export const SvgMobileFilled = (props) => {
    const { width, height, color } = props;

    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M15.045,0c-.027-.001-6.063-.001-6.09,0-2.736,.024-4.955,2.258-4.955,4.999v14c0,2.757,2.243,5,5,5h6c2.757,0,5-2.243,5-5V5C20,2.258,17.781,.025,15.045,0Zm2.955,18.999c0,1.654-1.346,3-3,3h-6c-1.654,0-3-1.346-3-3V5c0-1.453,1.038-2.667,2.411-2.942l.694,1.389c.169,.339,.516,.553,.895,.553h4c.379,0,.725-.214,.895-.553l.694-1.389c1.373,.274,2.411,1.489,2.411,2.942v14Zm-5,1h-2c-.552,0-1-.448-1-1h0c0-.552,.448-1,1-1h2c.552,0,1,.448,1,1h0c0,.552-.448,1-1,1Z" /></svg>

}


export const SvgLockFilled = (props) => {
    const { width, height, color } = props;

    return <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M19,8.424V7A7,7,0,0,0,5,7V8.424A5,5,0,0,0,2,13v6a5.006,5.006,0,0,0,5,5H17a5.006,5.006,0,0,0,5-5V13A5,5,0,0,0,19,8.424ZM7,7A5,5,0,0,1,17,7V8H7ZM20,19a3,3,0,0,1-3,3H7a3,3,0,0,1-3-3V13a3,3,0,0,1,3-3H17a3,3,0,0,1,3,3Z" /><path d="M12,14a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V15A1,1,0,0,0,12,14Z" /></svg>
}


export const SvgLocationOutlined = (props) => {
    const { width, height, color } = props;

    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M24,20c0,2.206-1.794,4-4,4H7.854l2.045-2h10.102c1.103,0,2-.897,2-2s-.897-2-2-2h-5c-2.206,0-4-1.794-4-4,0-1.927,1.37-3.539,3.187-3.916l1.959,1.916h-1.146c-1.103,0-2,.897-2,2s.897,2,2,2h5c2.206,0,4,1.794,4,4Zm-1.465-11.464c1.95-1.95,1.95-5.122,0-7.071-.944-.944-2.2-1.464-3.535-1.464s-2.591,.52-3.536,1.464c-1.949,1.95-1.949,5.122,.008,7.079l3.528,3.45,3.535-3.458Zm-1.414-5.657c1.17,1.17,1.17,3.073,.008,4.235l-2.129,2.082-2.122-2.074c-1.169-1.17-1.169-3.073,0-4.243,.567-.567,1.32-.879,2.122-.879s1.555,.312,2.121,.878ZM8.535,20.536c1.95-1.95,1.95-5.122,0-7.071-.944-.944-2.2-1.464-3.535-1.464s-2.591,.52-3.536,1.464c-1.949,1.95-1.949,5.122,.008,7.079l3.528,3.45,3.535-3.458Zm-1.414-5.657c1.17,1.17,1.17,3.073,.008,4.235l-2.129,2.082-2.122-2.074c-1.169-1.17-1.169-3.073,0-4.243,.567-.567,1.32-.879,2.122-.879s1.555,.312,2.121,.878Z" /></svg>
}


export const SvgHistoryOutlined = (props) => {
    const { width, height, color } = props;

    return <svg xmlns="http://www.w3.org/2000/svg" id="Filled" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M12,0A11.972,11.972,0,0,0,4,3.073V1A1,1,0,0,0,2,1V4A3,3,0,0,0,5,7H8A1,1,0,0,0,8,5H5a.854.854,0,0,1-.1-.021A9.987,9.987,0,1,1,2,12a1,1,0,0,0-2,0A12,12,0,1,0,12,0Z" /><path d="M12,6a1,1,0,0,0-1,1v5a1,1,0,0,0,.293.707l3,3a1,1,0,0,0,1.414-1.414L13,11.586V7A1,1,0,0,0,12,6Z" /></svg>
}


export const SvgLogoutOutlined = (props) => {
    const { width, height, color } = props;


    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width} height={height} fill={color}><g id="_01_align_center" data-name="01 align center"><path d="M2,21V3A1,1,0,0,1,3,2H8V0H3A3,3,0,0,0,0,3V21a3,3,0,0,0,3,3H8V22H3A1,1,0,0,1,2,21Z" /><path d="M23.123,9.879,18.537,5.293,17.123,6.707l4.264,4.264L5,11l0,2,16.443-.029-4.322,4.322,1.414,1.414,4.586-4.586A3,3,0,0,0,23.123,9.879Z" /></g></svg>
}
export const SvgOrderDollyOutlined = (props) => {
    const { width, height, color } = props;

    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}>
        <path d="m1,2.5C1,1.119,2.119,0,3.5,0s2.5,1.119,2.5,2.5-1.119,2.5-2.5,2.5S1,3.881,1,2.5Zm22.226,16.744l-7.291,1.687c.043.183.066.373.066.569,0,1.598-1.499,2.845-3.167,2.414-.849-.22-1.541-.919-1.753-1.77-.362-1.452.544-2.768,1.842-3.076l-1.369-6.068h-2.935c-.758,0-1.45-.428-1.789-1.106l-1.369-2.739c-.117-.235-.274-.44-.459-.61v7.687l.546.329c.902.543,1.454,1.518,1.454,2.571v3.868c0,.553-.448,1-1,1s-1-.447-1-1v-3.869c0-.351-.184-.675-.484-.856l-2.764-1.664C.715,15.987.074,14.871.057,13.661l-.057-4.058c0-2.576,2.69-4.512,5.389-3.16.865.433,1.539,1.176,1.972,2.042l1.257,2.515h2.484l-1.078-4.78c-.122-.538.217-1.073.755-1.195.54-.122,1.074.217,1.196.756l3.004,13.318,7.795-1.804c.54-.126,1.075.211,1.2.749.125.538-.21,1.075-.749,1.2Zm-21.226-.244c-.552,0-1,.447-1,1v3c0,.553.448,1,1,1s1-.447,1-1v-3c0-.553-.448-1-1-1Zm13.067-7.251c-.316-1.351.523-2.709,1.872-3.028l2.775-.66c.658-.156,1.335-.047,1.909.309.574.354.975.912,1.129,1.568l.668,2.852c.316,1.351-.523,2.709-1.872,3.028l-2.775.66c-.195.047-.392.069-.587.069-.463,0-.918-.128-1.321-.378-.574-.354-.975-.912-1.129-1.569l-.668-2.851Z" />
    </svg>


    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}>
        <path d="m1,2.5C1,1.119,2.119,0,3.5,0s2.5,1.119,2.5,2.5-1.119,2.5-2.5,2.5S1,3.881,1,2.5Zm22.974,15.544c-.125-.538-.66-.875-1.2-.749l-7.795,1.804-3.004-13.318c-.122-.539-.656-.878-1.196-.756-.539.122-.877.657-.755,1.195l1.078,4.78h-2.484l-1.37-2.739c-.697-1.395-2.099-2.261-3.658-2.261-1.979,0-3.59,1.61-3.59,3.604l.053,3.78c.02,1.393.744,2.653,1.938,3.371l2.524,1.52c.299.179.484.508.484.856v3.869c0,.553.448,1,1,1s1-.447,1-1v-3.869c0-1.047-.557-2.032-1.454-2.57l-2.524-1.519c-.597-.359-.959-.99-.969-1.686l-.053-3.767c0-.877.713-1.59,1.59-1.59.14,0,.277.014.41.04v4.96c0,.553.448,1,1,1s1-.447,1-1v-2.764l.829,1.658c.341.682,1.026,1.105,1.789,1.105h2.935l1.369,6.068c-1.102.261-1.922,1.25-1.922,2.432,0,1.381,1.119,2.5,2.5,2.5s2.5-1.119,2.5-2.5c0-.196-.023-.386-.066-.569l7.291-1.687c.538-.125.873-.662.749-1.2Zm-21.974.956c-.552,0-1,.447-1,1v3c0,.553.448,1,1,1s1-.447,1-1v-3c0-.553-.448-1-1-1Zm13.067-7.251c-.316-1.351.523-2.709,1.872-3.028l2.775-.66c.658-.156,1.335-.047,1.909.309.574.354.975.912,1.129,1.568l.668,2.852c.316,1.351-.523,2.709-1.872,3.028l-2.775.66c-.195.047-.392.069-.587.069-.463,0-.918-.128-1.321-.378-.574-.354-.975-.912-1.129-1.569l-.668-2.851Zm1.947-.457l.668,2.851c.032.137.115.252.234.325.118.073.258.094.395.064l2.775-.66c.279-.066.453-.348.387-.626l-.668-2.852c-.032-.136-.115-.251-.233-.324-.119-.073-.261-.094-.396-.064l-2.775.66c-.279.066-.453.348-.387.626Z" />
    </svg>
}


export const SvgTandCOutlined = (props) => {
    const { width, height, color } = props;



    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M17,24H7c-2.757,0-5-2.243-5-5V5C2,2.243,4.243,0,7,0h10c2.757,0,5,2.243,5,5v14c0,2.757-2.243,5-5,5ZM7,2c-1.654,0-3,1.346-3,3v14c0,1.654,1.346,3,3,3h10c1.654,0,3-1.346,3-3V5c0-1.654-1.346-3-3-3H7Zm11,4c0-.552-.448-1-1-1h-4c-.552,0-1,.448-1,1s.448,1,1,1h4c.552,0,1-.448,1-1Zm0,6c0-.552-.448-1-1-1h-4c-.552,0-1,.448-1,1s.448,1,1,1h4c.552,0,1-.448,1-1Zm0,6c0-.552-.448-1-1-1h-4c-.552,0-1,.448-1,1s.448,1,1,1h4c.552,0,1-.448,1-1ZM10,7v-2c0-.552-.448-1-1-1h-2c-.552,0-1,.448-1,1v2c0,.552,.448,1,1,1h2c.552,0,1-.448,1-1Zm0,6v-2c0-.552-.448-1-1-1h-2c-.552,0-1,.448-1,1v2c0,.552,.448,1,1,1h2c.552,0,1-.448,1-1Zm0,6v-2c0-.552-.448-1-1-1h-2c-.552,0-1,.448-1,1v2c0,.552,.448,1,1,1h2c.552,0,1-.448,1-1Z" /></svg>
}

export const SvgNavigatorOutlined = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M13.987,6.108c-.039.011-7.228,2.864-7.228,2.864a2.76,2.76,0,0,0,.2,5.212l2.346.587.773,2.524A2.739,2.739,0,0,0,12.617,19h.044a2.738,2.738,0,0,0,2.532-1.786s2.693-7.165,2.7-7.2a3.2,3.2,0,0,0-3.908-3.907ZM15.97,9.467,13.322,16.51a.738.738,0,0,1-.692.49c-.1-.012-.525-.026-.675-.378l-.908-2.976a1,1,0,0,0-.713-.679l-2.818-.7a.762.762,0,0,1-.027-1.433l7.06-2.8a1.149,1.149,0,0,1,1.094.32A1.19,1.19,0,0,1,15.97,9.467ZM12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm0,22A10,10,0,1,1,22,12,10.011,10.011,0,0,1,12,22Z" /></svg>
}

export const SvgPhoneCircleOutlined = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M12,24C5.383,24,0,18.617,0,12S5.383,0,12,0s12,5.383,12,12-5.383,12-12,12Zm0-21C7.038,3,3,7.038,3,12s4.038,9,9,9,9-4.038,9-9S16.962,3,12,3ZM7.636,13.971l-1.302,1c-.446,.446-.446,1.17,0,1.616l.522,.6c.551,.552,1.277,.813,2,.813,3.714,0,9.143-5.143,9.143-9.143,0-.723-.261-1.449-.813-2l-.6-.522c-.446-.446-1.17-.446-1.616,0l-1,1.302c-.446,.446-.446,1.17,0,1.616l.851,1.069c-.901,2.244-2.429,3.71-4.5,4.5l-1.069-.851c-.446-.446-1.17-.446-1.616,0Z" /></svg>
}

export const SvgBrushOutlined = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M23.715,2.285a1.013,1.013,0,0,0-1.43,0L17.521,7.049l-.32-.313a5.008,5.008,0,0,0-6.429-.479A16.618,16.618,0,0,1,6.224,8.685L4.15,9.293a5.036,5.036,0,0,0-3.113,2.635A4.973,4.973,0,0,0,.9,15.947a12.95,12.95,0,0,0,12.112,8.064h.924a1.011,1.011,0,0,0,.578-.182A15.288,15.288,0,0,0,21.224,13.62a5.029,5.029,0,0,0-1.453-4.374l-.8-.784,4.747-4.747A1.013,1.013,0,0,0,23.715,2.285Zm-10.107,19.7h-.6A11.3,11.3,0,0,1,8.7,21.138l.011-.006a11.546,11.546,0,0,0,4.351-3.8l.518-.761a1.01,1.01,0,0,0-1.67-1.138l-.518.761A9.535,9.535,0,0,1,7.8,19.327l-1.251.63a10.757,10.757,0,0,1-2.583-2.57,11.625,11.625,0,0,0,4.377-2.664,1.011,1.011,0,0,0-1.414-1.446,9.617,9.617,0,0,1-3.98,2.32c-.061-.135-.127-.267-.182-.406a2.906,2.906,0,0,1,.085-2.381,3.023,3.023,0,0,1,1.864-1.578l2.073-.608a15.364,15.364,0,0,0,3.426-1.588l7.915,7.712A14.192,14.192,0,0,1,13.608,21.989Zm5.62-8.683a12.421,12.421,0,0,1-.309,1.387L11.948,7.9l0,0a3.011,3.011,0,0,1,1.755-.566,2.973,2.973,0,0,1,2.084.849l2.569,2.509A3.01,3.01,0,0,1,19.228,13.306Z" /></svg>
}

export const SvgLearningOutlined = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="m7,14c2.206,0,4-1.794,4-4s-1.794-4-4-4-4,1.794-4,4,1.794,4,4,4Zm0-6c1.103,0,2,.897,2,2s-.897,2-2,2-2-.897-2-2,.897-2,2-2Zm7,15c0,.553-.448,1-1,1s-1-.447-1-1c0-2.757-2.243-5-5-5s-5,2.243-5,5c0,.553-.448,1-1,1s-1-.447-1-1c0-3.859,3.14-7,7-7s7,3.141,7,7ZM24,5v8c0,2.757-2.243,5-5,5h-4c-.552,0-1-.447-1-1v-2c0-.553.448-1,1-1h3c.552,0,1,.447,1,1v1c1.654,0,3-1.346,3-3V5c0-1.654-1.346-3-3-3h-9.535c-1.068,0-2.064.575-2.599,1.501-.277.478-.888.643-1.366.364-.479-.276-.642-.888-.365-1.366.892-1.541,2.551-2.499,4.331-2.499h9.535c2.757,0,5,2.243,5,5Z" /></svg>

}

export const SvgCircleOneFill = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm1,17c0,.553-.448,1-1,1s-1-.447-1-1v-7.586l-.737,.737c-.391,.391-1.023,.391-1.414,0s-.391-1.023,0-1.414l2.444-2.444c.285-.286,.714-.374,1.09-.217,.374,.154,.617,.52,.617,.924v10Z" /></svg>


}

export const SvgCircleTwoFill = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm3,18h-5.162c-.724,0-1.362-.407-1.666-1.062-.307-.664-.203-1.424,.272-1.983,.634-.748,1.569-1.257,2.559-1.797,1.906-1.037,3.058-1.777,2.994-3.29-.043-1.029-.939-1.867-1.997-1.867-1.103,0-2,.897-2,2,0,.553-.448,1-1,1s-1-.447-1-1c0-2.206,1.794-4,4-4,2.151,0,3.906,1.662,3.995,3.783,.122,2.866-2.281,4.175-4.035,5.131-.673,.366-1.315,.716-1.742,1.086h4.781c.552,0,1,.447,1,1s-.448,1-1,1Z" /></svg>

}

export const SvgCircleThreeFill = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,18h-3c-.552,0-1-.447-1-1s.448-1,1-1h3c1.103,0,2-.897,2-2s-.897-2-2-2h-2c-.552,0-1-.447-1-1s.448-1,1-1h2c.551,0,1-.448,1-1s-.449-1-1-1h-3c-.552,0-1-.447-1-1s.448-1,1-1h3c1.654,0,3,1.346,3,3,0,.68-.236,1.301-.619,1.805,.977,.73,1.619,1.885,1.619,3.195,0,2.206-1.794,4-4,4Z" /></svg>

}


export const SvgNotificationOutlined = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M22.555,13.662l-1.9-6.836A9.321,9.321,0,0,0,2.576,7.3L1.105,13.915A5,5,0,0,0,5.986,20H7.1a5,5,0,0,0,9.8,0h.838a5,5,0,0,0,4.818-6.338ZM12,22a3,3,0,0,1-2.816-2h5.632A3,3,0,0,1,12,22Zm8.126-5.185A2.977,2.977,0,0,1,17.737,18H5.986a3,3,0,0,1-2.928-3.651l1.47-6.616a7.321,7.321,0,0,1,14.2-.372l1.9,6.836A2.977,2.977,0,0,1,20.126,16.815Z" /></svg>
}


export const SvgChevronRight = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M15.4,9.88,10.81,5.29a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42L14,11.29a1,1,0,0,1,0,1.42L9.4,17.29a1,1,0,0,0,1.41,1.42l4.59-4.59A3,3,0,0,0,15.4,9.88Z" /></svg>

}

export const SvgChevronDown = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M18.71,8.21a1,1,0,0,0-1.42,0l-4.58,4.58a1,1,0,0,1-1.42,0L6.71,8.21a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41l4.59,4.59a3,3,0,0,0,4.24,0l4.59-4.59A1,1,0,0,0,18.71,8.21Z" /></svg>

}

export const SvgFAQOutlined = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M11.904,16c.828,0,1.5,.672,1.5,1.5s-.672,1.5-1.5,1.5-1.5-.672-1.5-1.5,.672-1.5,1.5-1.5Zm1-2c0-.561,.408-1.225,.928-1.512,1.5-.826,2.307-2.523,2.009-4.223-.283-1.613-1.607-2.938-3.221-3.221-1.182-.204-2.38,.112-3.289,.874-.907,.763-1.428,1.879-1.428,3.063,0,.553,.448,1,1,1s1-.447,1-1c0-.592,.26-1.15,.714-1.532,.461-.386,1.052-.542,1.657-.435,.787,.138,1.458,.81,1.596,1.596,.153,.871-.241,1.705-1.004,2.125-1.156,.637-1.963,1.979-1.963,3.264,0,.553,.448,1,1,1s1-.447,1-1Zm11.096,5v-6.66C24,5.861,19.096,.454,12.836,.028,9.361-.202,5.961,1.066,3.509,3.521,1.057,5.977-.211,9.378,.03,12.854c.44,6.354,6.052,11.146,13.054,11.146h5.917c2.757,0,5-2.243,5-5ZM12.701,2.024c5.215,.354,9.299,4.885,9.299,10.315v6.66c0,1.654-1.346,3-3,3h-5.917c-6.035,0-10.686-3.904-11.059-9.284-.201-2.899,.855-5.735,2.899-7.781,1.882-1.885,4.435-2.934,7.092-2.934,.228,0,.457,.008,.685,.023Z" /></svg>

}


export const SvgCorrectFill = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm-.091,15.419c-.387.387-.896.58-1.407.58s-1.025-.195-1.416-.585l-2.782-2.696,1.393-1.437,2.793,2.707,5.809-5.701,1.404,1.425-5.793,5.707Z" /></svg>
}

export const SvgMyanmarFlag = (props) => {
    const { width, height, color, style, onClick } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" id="myanmar" width={width} height={height} style={style} onClick={onClick}><path fill="#6da544" d="M0 85.337h512v341.326H0z"></path><path fill="#ffda44" d="M0 85.337h512v113.775H0z"></path><path fill="#d80027" d="M0 312.888h512v113.775H0z"></path><path fill="#f0f0f0" d="M384 227.261h-97.783L256 134.265l-30.217 92.997H128l79.108 57.475-30.217 92.998L256 320.925l79.108 56.81-30.217-92.998L384 227.261z"></path></svg>
}

export const SvgUKFlag = (props) => {
    const { width, height, color, style, onClick } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" id="united-kingdom" width={width} height={height} style={style} onClick={onClick}><path fill="#f0f0f0" d="M0 85.333h512V426.67H0z"></path><path fill="#d80027" d="M288 85.33h-64v138.666H0v64h224v138.666h64V287.996h224v-64H288z"></path><path fill="#0052b4" d="M393.785 315.358 512 381.034v-65.676zM311.652 315.358 512 426.662v-31.474l-143.693-79.83zM458.634 426.662l-146.982-81.664v81.664z"></path><path fill="#f0f0f0" d="M311.652 315.358 512 426.662v-31.474l-143.693-79.83z"></path><path fill="#d80027" d="M311.652 315.358 512 426.662v-31.474l-143.693-79.83z"></path><path fill="#0052b4" d="M90.341 315.356 0 365.546v-50.19zM200.348 329.51v97.151H25.491z"></path><path fill="#d80027" d="M143.693 315.358 0 395.188v31.474l200.348-111.304z"></path><path fill="#0052b4" d="M118.215 196.634 0 130.958v65.676zM200.348 196.634 0 85.33v31.474l143.693 79.83zM53.366 85.33l146.982 81.664V85.33z"></path><path fill="#f0f0f0" d="M200.348 196.634 0 85.33v31.474l143.693 79.83z"></path><path fill="#d80027" d="M200.348 196.634 0 85.33v31.474l143.693 79.83z"></path><path fill="#0052b4" d="M421.659 196.636 512 146.446v50.19zM311.652 182.482V85.331h174.857z"></path><path fill="#d80027" d="M368.307 196.634 512 116.804V85.33L311.652 196.634z"></path></svg>
}

export const SvgPdfOutlined = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M20.1,5.39l-3.49-3.49c-1.23-1.23-2.86-1.9-4.6-1.9H6.5C4.02,0,2,2.02,2,4.5v15c0,2.48,2.02,4.5,4.5,4.5h11c2.48,0,4.5-2.02,4.5-4.5V9.99c0-1.74-.68-3.37-1.9-4.6Zm-.71,.71c.55,.55,.97,1.2,1.24,1.9h-5.13c-.83,0-1.5-.67-1.5-1.5V1.37c.71,.27,1.35,.69,1.9,1.24l3.49,3.49Zm1.61,13.4c0,1.93-1.57,3.5-3.5,3.5H6.5c-1.93,0-3.5-1.57-3.5-3.5V4.5c0-1.93,1.57-3.5,3.5-3.5h5.51c.33,0,.66,.03,.99,.09V6.5c0,1.38,1.12,2.5,2.5,2.5h5.41c.06,.32,.09,.65,.09,.99v9.51ZM6.97,13h-.97c-.55,0-1,.45-1,1v4.5c0,.28,.22,.5,.5,.5s.5-.22,.5-.5v-1.5h.97c1.11,0,2.01-.92,2.01-2.02s-.9-1.98-2.01-1.98Zm0,3h-.97v-2h.97c.56,0,1.01,.44,1.01,.98s-.46,1.02-1.01,1.02Zm5.03-3h0s-.51,0-1,0c-.55,0-1,.45-1,1v4c0,.55,.45,1,1,1,.48,0,.97,0,1,0h0c1.11,0,2-.89,2-1.98v-2.03c0-1.09-.89-1.98-2-1.98Zm1,4.02c0,.53-.43,.96-.96,.98h-1.04v-4h1.04c.53,.02,.96,.46,.96,.98v2.03Zm6-3.52c0,.28-.22,.5-.5,.5h-2.5v2s1.5,0,1.5,0c.28,0,.5,.22,.5,.5s-.22,.5-.5,.5h-1.5v1.5c0,.28-.22,.5-.5,.5s-.5-.22-.5-.5v-4.5c0-.55,.45-1,1-1h2.5c.28,0,.5,.22,.5,.5Z" /></svg>
}

export const SvgExcelOutlined = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M15.38,12.83l-2.72,3.17,2.72,3.17c.18,.21,.16,.52-.05,.7-.09,.08-.21,.12-.33,.12-.14,0-.28-.06-.38-.17l-2.62-3.06-2.62,3.06c-.1,.12-.24,.17-.38,.17-.12,0-.23-.04-.33-.12-.21-.18-.23-.5-.05-.7l2.72-3.17-2.72-3.17c-.18-.21-.16-.52,.05-.7,.21-.18,.52-.16,.71,.05l2.62,3.06,2.62-3.06c.18-.21,.5-.23,.71-.05,.21,.18,.23,.5,.05,.7Zm6.62-2.83v9.51c0,2.48-2.02,4.5-4.5,4.5H6.5c-2.48,0-4.5-2.02-4.5-4.5V4.51C2,2.03,4.02,.01,6.5,.01h5.51c1.74,0,3.37,.68,4.6,1.9l3.48,3.49c1.23,1.23,1.9,2.86,1.9,4.6ZM15.9,2.63c-.55-.55-1.2-.97-1.9-1.24V6.51c0,.83,.67,1.5,1.5,1.5h5.13c-.27-.71-.69-1.35-1.24-1.9l-3.48-3.49Zm5.1,7.37c0-.33-.03-.66-.09-.99h-5.41c-1.38,0-2.5-1.12-2.5-2.5V1.1c-.32-.06-.65-.09-.99-.09H6.5c-1.93,0-3.5,1.57-3.5,3.5v15c0,1.93,1.57,3.5,3.5,3.5h11c1.93,0,3.5-1.57,3.5-3.5V10Z" /></svg>
}


export const SvgPngOutlined = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="m6,10c0-.552.448-1,1-1s1,.448,1,1-.448,1-1,1-1-.448-1-1Zm16-.015v9.515c0,2.485-2.015,4.5-4.5,4.5H6.5c-2.485,0-4.5-2.015-4.5-4.5V4.5C2,2.015,4.015,0,6.5,0h5.515c1.724,0,3.377.685,4.596,1.904l3.485,3.485c1.219,1.219,1.904,2.872,1.904,4.596Zm-6.096-7.375c-.551-.551-1.198-.97-1.904-1.243v5.132c0,.827.673,1.5,1.5,1.5h5.132c-.273-.706-.692-1.353-1.243-1.904l-3.485-3.485ZM3,19.5c0,.524.116,1.022.324,1.469l6.092-6.092c1.149-1.149,3.02-1.149,4.169,0l.615.615c.717.717,1.884.717,2.601,0l4.2-4.2v-1.308c0-.334-.03-.663-.088-.985h-5.412c-1.378,0-2.5-1.122-2.5-2.5V1.088c-.322-.058-.651-.088-.985-.088h-5.515c-1.93,0-3.5,1.57-3.5,3.5v15Zm18,0v-6.793l-3.493,3.493c-1.106,1.107-2.908,1.107-4.015,0l-.615-.615c-.76-.76-1.995-.76-2.755,0l-6.238,6.238c.642.722,1.576,1.177,2.616,1.177h11c1.93,0,3.5-1.57,3.5-3.5Z" /></svg>
}

export const SvgInvoiceOutlined = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M16,23c0,.552-.447,1-1,1H6c-2.757,0-5-2.243-5-5V5C1,2.243,3.243,0,6,0h4.515c1.869,0,3.627,.728,4.95,2.05l3.484,3.486c.271,.271,.523,.568,.748,.883,.321,.449,.217,1.074-.232,1.395-.449,.32-1.075,.217-1.395-.233-.161-.225-.341-.438-.534-.63l-3.485-3.486c-.318-.318-.671-.587-1.051-.805V7c0,.551,.448,1,1,1h3c.553,0,1,.448,1,1s-.447,1-1,1h-3c-1.654,0-3-1.346-3-3V2.023c-.16-.015-.322-.023-.485-.023H6c-1.654,0-3,1.346-3,3v14c0,1.654,1.346,3,3,3H15c.553,0,1,.448,1,1Zm5.685-6.733l-3.041-.507c-.373-.062-.644-.382-.644-.76,0-.551,.448-1,1-1h2.268c.356,0,.688,.192,.867,.5,.275,.478,.885,.641,1.366,.365,.478-.277,.642-.888,.364-1.366-.534-.925-1.53-1.5-2.598-1.5h-.268v-1c0-.552-.447-1-1-1s-1,.448-1,1v1c-1.654,0-3,1.346-3,3,0,1.36,.974,2.51,2.315,2.733l3.041,.507c.373,.062,.644,.382,.644,.76,0,.551-.448,1-1,1h-2.268c-.356,0-.688-.192-.867-.5-.275-.479-.886-.642-1.366-.365-.478,.277-.642,.888-.364,1.366,.534,.925,1.53,1.499,2.598,1.499h.268v1c0,.552,.447,1,1,1s1-.448,1-1v-1c1.654,0,3-1.346,3-3,0-1.36-.974-2.51-2.315-2.733Zm-14.185-1.267h5.5c.553,0,1-.448,1-1s-.447-1-1-1H7.5c-1.378,0-2.5,1.122-2.5,2.5v2c0,1.378,1.122,2.5,2.5,2.5h5.5c.553,0,1-.448,1-1s-.447-1-1-1H7.5c-.276,0-.5-.224-.5-.5v-2c0-.276,.224-.5,.5-.5Zm-1.5-4h2c.552,0,1-.448,1-1s-.448-1-1-1h-2c-.552,0-1,.448-1,1s.448,1,1,1Zm0-4h2c.552,0,1-.448,1-1s-.448-1-1-1h-2c-.552,0-1,.448-1,1s.448,1,1,1Z" /></svg>
}

export const SvgOrderIncomplete = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="m23.341,9.48l-3.501-6c-.893-1.53-2.547-2.48-4.318-2.48h-7.071c-1.771,0-3.426.951-4.319,2.48L.631,9.48c-.906,1.554-.906,3.485,0,5.039l3.501,6c.893,1.53,2.547,2.48,4.318,2.48h7.071c1.771,0,3.426-.951,4.319-2.48l3.5-6c.906-1.554.906-3.485,0-5.039Zm-7.634,4.812c.391.391.391,1.023,0,1.414-.195.195-.451.293-.707.293s-.512-.098-.707-.293l-2.293-2.293-2.293,2.293c-.195.195-.451.293-.707.293s-.512-.098-.707-.293c-.391-.391-.391-1.023,0-1.414l2.293-2.293-2.293-2.293c-.391-.391-.391-1.023,0-1.414s1.023-.391,1.414,0l2.293,2.293,2.293-2.293c.391-.391,1.023-.391,1.414,0s.391,1.023,0,1.414l-2.293,2.293,2.293,2.293Z" /></svg>
}

export const SvgNotiActivity = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M4.068,18H19.724a3,3,0,0,0,2.821-4.021L19.693,6.094A8.323,8.323,0,0,0,11.675,0h0A8.321,8.321,0,0,0,3.552,6.516l-2.35,7.6A3,3,0,0,0,4.068,18Z" /><path d="M7.1,20a5,5,0,0,0,9.8,0Z" /></svg>
}

export const SvgNotiSystem = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="m1,2.5C1,1.119,2.119,0,3.5,0s2.5,1.119,2.5,2.5-1.119,2.5-2.5,2.5S1,3.881,1,2.5Zm22.226,16.744l-7.291,1.687c.043.183.066.373.066.569,0,1.598-1.499,2.845-3.167,2.414-.849-.22-1.541-.919-1.753-1.77-.362-1.452.544-2.768,1.842-3.076l-1.369-6.068h-2.935c-.758,0-1.45-.428-1.789-1.106l-1.369-2.739c-.117-.235-.274-.44-.459-.61v7.687l.546.329c.902.543,1.454,1.518,1.454,2.571v3.868c0,.553-.448,1-1,1s-1-.447-1-1v-3.869c0-.351-.184-.675-.484-.856l-2.764-1.664C.715,15.987.074,14.871.057,13.661l-.057-4.058c0-2.576,2.69-4.512,5.389-3.16.865.433,1.539,1.176,1.972,2.042l1.257,2.515h2.484l-1.078-4.78c-.122-.538.217-1.073.755-1.195.54-.122,1.074.217,1.196.756l3.004,13.318,7.795-1.804c.54-.126,1.075.211,1.2.749.125.538-.21,1.075-.749,1.2Zm-21.226-.244c-.552,0-1,.447-1,1v3c0,.553.448,1,1,1s1-.447,1-1v-3c0-.553-.448-1-1-1Zm13.067-7.251c-.316-1.351.523-2.709,1.872-3.028l2.775-.66c.658-.156,1.335-.047,1.909.309.574.354.975.912,1.129,1.568l.668,2.852c.316,1.351-.523,2.709-1.872,3.028l-2.775.66c-.195.047-.392.069-.587.069-.463,0-.918-.128-1.321-.378-.574-.354-.975-.912-1.129-1.569l-.668-2.851Z" /></svg>
}

export const SvgNotiOrder = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="m1,2.5C1,1.119,2.119,0,3.5,0s2.5,1.119,2.5,2.5-1.119,2.5-2.5,2.5S1,3.881,1,2.5Zm22.226,16.744l-7.291,1.687c.043.183.066.373.066.569,0,1.598-1.499,2.845-3.167,2.414-.849-.22-1.541-.919-1.753-1.77-.362-1.452.544-2.768,1.842-3.076l-1.369-6.068h-2.935c-.758,0-1.45-.428-1.789-1.106l-1.369-2.739c-.117-.235-.274-.44-.459-.61v7.687l.546.329c.902.543,1.454,1.518,1.454,2.571v3.868c0,.553-.448,1-1,1s-1-.447-1-1v-3.869c0-.351-.184-.675-.484-.856l-2.764-1.664C.715,15.987.074,14.871.057,13.661l-.057-4.058c0-2.576,2.69-4.512,5.389-3.16.865.433,1.539,1.176,1.972,2.042l1.257,2.515h2.484l-1.078-4.78c-.122-.538.217-1.073.755-1.195.54-.122,1.074.217,1.196.756l3.004,13.318,7.795-1.804c.54-.126,1.075.211,1.2.749.125.538-.21,1.075-.749,1.2Zm-21.226-.244c-.552,0-1,.447-1,1v3c0,.553.448,1,1,1s1-.447,1-1v-3c0-.553-.448-1-1-1Zm13.067-7.251c-.316-1.351.523-2.709,1.872-3.028l2.775-.66c.658-.156,1.335-.047,1.909.309.574.354.975.912,1.129,1.568l.668,2.852c.316,1.351-.523,2.709-1.872,3.028l-2.775.66c-.195.047-.392.069-.587.069-.463,0-.918-.128-1.321-.378-.574-.354-.975-.912-1.129-1.569l-.668-2.851Z" /></svg>

}

export const SvgNotiAnnocement = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="m22,11.95v6.05c0,.521-.399.954-.917.997-.028.002-.056.003-.083.003-.484,0-.904-.35-.985-.835-.389-2.333-3.62-3.165-6.514-3.165h-4.5V4h4.5c2.894,0,6.125-.831,6.514-3.165.086-.513.555-.88,1.069-.832.519.043.917.476.917.997v6.05c1.141.232,2,1.24,2,2.45s-.859,2.218-2,2.45Zm-15,3.05V4h-1.5C2.467,4,0,6.467,0,9.5s2.467,5.5,5.5,5.5h1.5Zm1.523,2h-3.023c-.878,0-1.721-.152-2.505-.431l2.171,5.792c.228.625.686,1.124,1.289,1.405.335.156.692.234,1.05.234.288,0,.577-.051.855-.152.625-.228,1.124-.686,1.405-1.289.281-.604.31-1.28.082-1.904l-1.325-3.656Z" /></svg>
}

export const SvgPrivacyPolicy = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="m18.573,2.126L12.314.051c-.203-.068-.426-.068-.629,0l-6.259,2.075c-2.05.68-3.427,2.587-3.427,4.746v5.171c0,6.563,7.005,10.577,9.152,11.65,0,0,.43.307.85.307s.791-.24.791-.24c2.16-.869,9.207-4.281,9.207-11.717v-5.171c0-2.159-1.377-4.066-3.427-4.746Zm1.427,9.917c0,6.182-6.088,9.111-7.953,9.861-1.889-.944-8.047-4.444-8.047-9.861v-5.171c0-1.295.826-2.439,2.056-2.848l5.944-1.971,5.944,1.971c1.229.408,2.056,1.553,2.056,2.848v5.171Zm-5.5-2.543c0,1.025-.617,1.906-1.5,2.291v3.209c0,.553-.447,1-1,1s-1-.447-1-1v-3.209c-.883-.386-1.5-1.266-1.5-2.291,0-1.381,1.119-2.5,2.5-2.5s2.5,1.119,2.5,2.5Z" /></svg>
}

export const SvgInfo = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="m11,1.5c0-.828.672-1.5,1.5-1.5s1.5.672,1.5,1.5-.672,1.5-1.5,1.5-1.5-.672-1.5-1.5Zm5.5,19.5h-2.5v-11.5c0-2.481-2.019-4.5-4.5-4.5h-1c-.829,0-1.5.671-1.5,1.5s.671,1.5,1.5,1.5h1c.827,0,1.5.673,1.5,1.5v11.5h-2.5c-.829,0-1.5.672-1.5,1.5s.671,1.5,1.5,1.5h8c.829,0,1.5-.672,1.5-1.5s-.671-1.5-1.5-1.5Z" /></svg>

}

export const SvgCheck = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="m23.637,10.55l-4.252-7.962c-.522-.979-1.536-1.587-2.646-1.587H7.282c-1.107,0-2.12.606-2.644,1.582L.365,10.549c-.488.911-.488,1.999,0,2.911l4.271,7.958c.523.976,1.536,1.582,2.644,1.582h9.457c1.11,0,2.124-.608,2.646-1.587l4.252-7.962c.485-.908.485-1.993,0-2.901Zm-11.728,4.87c-.386.387-.896.58-1.407.58s-1.025-.195-1.416-.585l-2.782-2.696,1.392-1.437,2.793,2.707,5.809-5.701,1.404,1.425-5.793,5.707Z" /></svg>
}

export const SvgAWB = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M16,0h-.13a2.02,2.02,0,0,0-1.941,1.532,2,2,0,0,1-3.858,0A2.02,2.02,0,0,0,8.13,0H8A5.006,5.006,0,0,0,3,5V21a3,3,0,0,0,3,3H8.13a2.02,2.02,0,0,0,1.941-1.532,2,2,0,0,1,3.858,0A2.02,2.02,0,0,0,15.87,24H18a3,3,0,0,0,3-3V5A5.006,5.006,0,0,0,16,0Zm2,22-2.143-.063A4,4,0,0,0,8.13,22H6a1,1,0,0,1-1-1V17H7a1,1,0,0,0,0-2H5V5A3,3,0,0,1,8,2l.143.063A4.01,4.01,0,0,0,12,5a4.071,4.071,0,0,0,3.893-3H16a3,3,0,0,1,3,3V15H17a1,1,0,0,0,0,2h2v4A1,1,0,0,1,18,22Z" /><path d="M13,15H11a1,1,0,0,0,0,2h2a1,1,0,0,0,0-2Z" /></svg>
}

export const SvgROM = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M12,2a10.032,10.032,0,0,1,7.122,3H16a1,1,0,0,0-1,1h0a1,1,0,0,0,1,1h4.143A1.858,1.858,0,0,0,22,5.143V1a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1V3.078A11.981,11.981,0,0,0,.05,10.9a1.007,1.007,0,0,0,1,1.1h0a.982.982,0,0,0,.989-.878A10.014,10.014,0,0,1,12,2Z" /><path d="M22.951,12a.982.982,0,0,0-.989.878A9.986,9.986,0,0,1,4.878,19H8a1,1,0,0,0,1-1H9a1,1,0,0,0-1-1H3.857A1.856,1.856,0,0,0,2,18.857V23a1,1,0,0,0,1,1H3a1,1,0,0,0,1-1V20.922A11.981,11.981,0,0,0,23.95,13.1a1.007,1.007,0,0,0-1-1.1Z" /></svg>
}

export const SvgRTS = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 64 64" id="return-delivery" width={width} height={height} fill={color}><path d="M21,32a1,1,0,0,1-.71-.29l-3.56-3.56-2.41.8A1,1,0,0,1,13,28V21a1,1,0,0,1,.64-.93l21-8a1,1,0,0,1,.71,1.87L15,21.69v4.92l1.68-.56a1,1,0,0,1,1,.24L20,28.59V24a1,1,0,0,1,.62-.93l21-8.59a1,1,0,0,1,.76,1.85L22,24.67V31a1,1,0,0,1-1,1Z"></path><path d="M28 57a1 1 0 01-.34-.06l-22-8A1 1 0 015 48V18a1 1 0 012 0V47.3l20 7.27V26a1 1 0 012 0V56a1 1 0 01-1 1zM50 26a1 1 0 01-1-1V18a1 1 0 012 0v7A1 1 0 0150 26z"></path><path d="M28 57a1 1 0 01-.34-1.94L49 47.3V45a1 1 0 012 0v3a1 1 0 01-.66.94l-22 8A1 1 0 0128 57zM28 27a1 1 0 01-.34-.06l-22-8a1 1 0 010-1.88l22-8a1 1 0 01.68 0l22 8a1 1 0 010 1.88l-22 8A1 1 0 0128 27zM8.93 18L28 24.94 47.07 18 28 11.06zM33 49.55a1 1 0 01-.34-1.94l4-1.45A1 1 0 0137.34 48l-4 1.45A1 1 0 0133 49.55zM33 45.55a1 1 0 01-.34-1.94l2-.73a1 1 0 01.68 1.88l-2 .73A1 1 0 0133 45.55z"></path><path d="M48.5,45a10.5,10.5,0,1,1,8.29-16.95,1,1,0,0,1-1.58,1.23A8.5,8.5,0,1,0,52.53,42a1,1,0,1,1,.95,1.76A10.53,10.53,0,0,1,48.5,45Z"></path><path d="M56,30H52a1,1,0,0,1,0-2h3V25a1,1,0,0,1,2,0v4A1,1,0,0,1,56,30Z"></path></svg>
}

export const SvgCloudDownload = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M17.974,7.146c-.332-.066-.603-.273-.742-.569-1.552-3.271-5.143-5.1-8.735-4.438-3.272,.6-5.837,3.212-6.384,6.501-.162,.971-.15,1.943,.033,2.89,.06,.309-.073,.653-.346,.901-1.145,1.041-1.801,2.524-1.801,4.07,0,3.032,2.467,5.5,5.5,5.5h11c4.136,0,7.5-3.364,7.5-7.5,0-3.565-2.534-6.658-6.026-7.354Zm-2.853,7.562l-2.707,2.707c-.387,.387-.896,.582-1.405,.584l-.009,.002-.009-.002c-.509-.002-1.018-.197-1.405-.584l-2.707-2.707c-.391-.391-.391-1.023,0-1.414s1.023-.391,1.414,0l1.707,1.707v-5c0-.553,.448-1,1-1s1,.447,1,1v5l1.707-1.707c.391-.391,1.023-.391,1.414,0s.391,1.023,0,1.414Z" /></svg>
}

export const SvgPowerOffOutlined = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96" id="switch" width={width} height={height} fill={color}><path d="M48 91.17a39.24 39.24 0 0 1-21.51-72 4 4 0 1 1 4.39 6.69 31.23 31.23 0 1 0 34.23 0 4 4 0 1 1 4.39-6.69 39.24 39.24 0 0 1-21.5 72Zm4-50V8.83a4 4 0 1 0-8 0v32.33a4 4 0 0 0 8 0Z" data-name="Artboard 30"></path></svg>
}

export const SvgStoreOutlined = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M3,11.874v4.126h7v2H3v3c0,.552,.448,1,1,1h6.078c.151,.744,.481,1.416,.922,2H4c-1.654,0-3-1.346-3-3V10.643c-.622-.705-1-1.631-1-2.643L2.246,0H21.754l2.246,8c0,.825-.251,1.592-.681,2.23-.348-.667-.818-1.258-1.38-1.748,.029-.115,.049-.233,.057-.355l-1.75-6.126h-3.246v3h-2V2h-6v3h-2V2H3.754l-1.75,6.126c.065,1.045,.936,1.874,1.996,1.874h1c1.103,0,2-.897,2-2h2c0,1.103,.897,2,2,2h1.809c-.352,.608-.599,1.282-.719,2h-1.09c-1.194,0-2.267-.525-3-1.357-.733,.832-1.806,1.357-3,1.357h-1c-.345,0-.68-.044-1-.126Zm16,6.126h-2v2h2v-2Zm3-4h2v7c0,1.654-1.346,3-3,3h-6c-1.654,0-3-1.346-3-3v-7h2v-1c0-2.206,1.794-4,4-4s4,1.794,4,4v1Zm-6,0h4v-1c0-1.103-.897-2-2-2s-2,.897-2,2v1Zm6,2H14v5c0,.552,.448,1,1,1h6c.552,0,1-.448,1-1v-5Z" /></svg>
}

export const SvgStoreFilled = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M23.962,7.725l-1.172-4.099c-.61-2.135-2.588-3.626-4.808-3.626h-.982V4c-.006,1.308-1.995,1.307-2,0V0h-6V4c-.006,1.308-1.994,1.307-2,0V0h-.983C3.797,0,1.82,1.491,1.209,3.626L.039,7.725c-.161,1.066,.314,2.138,.961,2.893v9.382c0,2.206,1.794,4,4,4h6c2.206,0,4-1.794,4-4V11.444c.378-.221,.714-.498,1-.826,.734,.84,1.799,1.382,3,1.382h1c.347,0,.678-.058,1-.142v11.142c.006,1.308,1.995,1.307,2,0V10.618c.648-.754,1.122-1.826,.962-2.893Zm-10.962,9.275H3v-5.142c.322,.084,.653,.142,1,.142h1c1.2,0,2.266-.542,3-1.382,.734,.84,1.8,1.382,3,1.382h2v5Z" /></svg>
}

export const SvgHistoryFilled = (props) => {
    const { width, height, color } = props;
    return <svg id="Layer_1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" width={width} height={height} fill={color}><path d="m11 17.5a6.5 6.5 0 0 1 11-4.684v-9.816a3 3 0 0 0 -3-3h-12a5.006 5.006 0 0 0 -5 5v14a5.006 5.006 0 0 0 5 5h10.5a6.5 6.5 0 0 1 -6.5-6.5zm-4-10.5a1 1 0 0 1 1-1h8a1 1 0 0 1 0 2h-8a1 1 0 0 1 -1-1zm16.707 16.707a1 1 0 0 1 -1.414 0l-2.407-2.407a4.457 4.457 0 0 1 -2.386.7 4.5 4.5 0 1 1 4.5-4.5 4.457 4.457 0 0 1 -.7 2.386l2.407 2.407a1 1 0 0 1 0 1.414z" /></svg>
}

export const SvgReportFilled = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M20.389,4.268l-2.656-2.657c-1.039-1.039-2.42-1.611-3.89-1.611H7.5C4.468,0,2,2.467,2,5.5v13c0,3.033,2.468,5.5,5.5,5.5h9c3.032,0,5.5-2.467,5.5-5.5V8.157c0-1.469-.572-2.85-1.611-3.889Zm-3.889,16.732H7.5c-1.379,0-2.5-1.122-2.5-2.5V5.5c0-1.378,1.121-2.5,2.5-2.5h5.5V7c0,1.105,.895,2,2,2h4v9.5c0,1.378-1.121,2.5-2.5,2.5Zm.114-8.923c1.403,3.18-1.117,7-4.614,6.923-2.703,0-5.001-2.297-5-5,0-2.761,2.239-5,5-5v3.5c0,.714,.726,1.198,1.385,.923l3.23-1.346Z" /></svg>
}
export const SvgNotificationBellFilled = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M4.068,18H19.724a3,3,0,0,0,2.821-4.021L19.693,6.094A8.323,8.323,0,0,0,11.675,0h0A8.321,8.321,0,0,0,3.552,6.516l-2.35,7.6A3,3,0,0,0,4.068,18Z" /><path d="M7.1,20a5,5,0,0,0,9.8,0Z" /></svg>
}

export const SvgCameraViewFinderFilled = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}>
        <path d="m7,24h-2c-2.757,0-5-2.243-5-5v-2c0-.553.447-1,1-1s1,.447,1,1v2c0,1.654,1.346,3,3,3h2c.553,0,1,.447,1,1s-.447,1-1,1Zm17-5v-2c0-.553-.447-1-1-1s-1,.447-1,1v2c0,1.654-1.346,3-3,3h-2c-.553,0-1,.447-1,1s.447,1,1,1h2c2.757,0,5-2.243,5-5Zm0-12v-2c0-2.757-2.243-5-5-5h-2c-.553,0-1,.447-1,1s.447,1,1,1h2c1.654,0,3,1.346,3,3v2c0,.553.447,1,1,1s1-.447,1-1Zm-22,0v-2c0-1.654,1.346-3,3-3h2c.553,0,1-.447,1-1s-.447-1-1-1h-2C2.243,0,0,2.243,0,5v2c0,.553.447,1,1,1s1-.447,1-1Zm16,3v4c0,1.654-1.346,3-3,3h-6c-1.654,0-3-1.346-3-3v-4c0-1.639,1.321-2.974,2.953-2.999l.696-1.083c.368-.574.997-.918,1.682-.918h1.338c.685,0,1.313.344,1.683.919l.695,1.082c1.633.025,2.953,1.36,2.953,2.999Zm-4,2c0-1.105-.895-2-2-2s-2,.895-2,2,.895,2,2,2,2-.895,2-2Z" />
    </svg>
}

export const SvgNoServiceFilled = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={width} height={height} fill={color} viewBox="0 0 50 50">
    <path d="M25,2C12.297,2,2,12.297,2,25s10.297,23,23,23s23-10.297,23-23S37.703,2,25,2z M25,11c1.657,0,3,1.343,3,3s-1.343,3-3,3 s-3-1.343-3-3S23.343,11,25,11z M29,38h-2h-4h-2v-2h2V23h-2v-2h2h4v2v13h2V38z"></path>
</svg>
}

export const SvgReceiveFilled = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M7.293,7.121c-.391-.391-.391-1.023,0-1.414s1.023-.391,1.414,0l2.293,2.293V1c0-.553,.447-1,1-1s1,.447,1,1v7l2.293-2.293c.391-.391,1.023-.391,1.414,0s.391,1.023,0,1.414l-3.293,3.293c-.387,.387-.896,.582-1.405,.584l-.009,.002-.009-.002c-.509-.002-1.018-.197-1.405-.584l-3.293-3.293Zm13.707,4.879h-3c-1.103,0-2,.897-2,2s-.897,2-2,2h-4c-1.103,0-2-.897-2-2s-.897-2-2-2H3c-1.654,0-3,1.346-3,3v4c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5v-4c0-1.654-1.346-3-3-3Z"/></svg>
}

export const SvgPickupFilled = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M11,1H3A3,3,0,0,0,0,4V14H14V4A3,3,0,0,0,11,1ZM4,11H2V4H4Zm4,0H6V4H8Zm2,0V4h2v7H10m6,0V5h3.559A3,3,0,0,1,22.4,7.051L23.721,11ZM0,18V16H16V13h8v5Zm.058,2c-.587,3.954,5.472,3.952,4.884,0Zm7,0c-.587,3.954,5.472,3.952,4.884,0Zm10,0c-.587,3.954,5.472,3.952,4.884,0Z" /></svg>
}