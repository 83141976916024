import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { accessToken, authenticated } from "../redux/auth-slice";
import { fullName, senderDivision, senderTownshipId } from "../redux/profile-slice";

const withRouter = WrappedComponent => props => {
    const parmas = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const translate = useTranslation();
    const isAuthenticated = useSelector(authenticated);
    const isAccessToken = useSelector(accessToken)
    const userName = useSelector(fullName)
    const senderTownship = useSelector(senderTownshipId) 
    const senderDivisionList = useSelector(senderDivision)
    
    return (
        <WrappedComponent
            {...props}
            authenticated={isAuthenticated}
            accessToken={isAccessToken}
            parmas={parmas}
            navigate={navigate}
            translate={translate}
            dispatch={dispatch}
            location={location}
            userName={userName}
            senderTownship={senderTownship}
            senderDivision={senderDivisionList}
            />
    )
}

export default withRouter;