import { Breadcrumb, Checkbox, Col, Form, Row, message } from "antd";
import dayjs from 'dayjs';
import i18next from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { NJVButton, NJVCard, NJVInput, NJVSelect } from "../../component/core-component";
import { AdminPasswordValidator, NumberAndDecimelPointValidatorNullable, PhoneNumberValidator } from "../../core/validator";
import Api from "../../network/api";
import { ApiInstance } from "../../network/api-manager";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

class PartnershipStoreForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            roleOptions: [],
            currentDate: '',
            fileList: [],
            singleFile: {},
            isLoading: false,
            division_collection: [],
            township_collection_map: {},
            township_collection: [],
        };
        this.inputRef = React.createRef()

    }

    componentDidMount() {
        const date = dayjs();
        this.setState({ currentDate: date.subtract(18, 'year') })
        this.fetchAllDivision()
    }

    // handlePhoneNumberChange = (e) => {
    //     const inputValue = e.target.value;
    //     const numericValue = inputValue.replace(/[^0-9]/g, '');
    //     this.inputRef.current.setFieldsValue({ primaryPhoneNumber: numericValue })
    // }

    fetchAllDivision = async () => {
        try {
            const response = await ApiInstance(Api.division_get_all, HTTP_METHOD.GET, MEDIA_TYPE.JSON)
            if (response) {
                let divisionList = []
                let townshipMap = {}
                response.map(division => {
                    divisionList.push(division)
                    townshipMap[division.id] = division.townshipDTOList
                })
                this.setState({
                    division_collection: divisionList,
                    township_collection_map: townshipMap
                })
                this.fetchData(townshipMap)
            }
        } catch (error) {
        }
    }

    savePartnershipAdmin = async (values) => {

        this.setState({
            isLoading: true
        })
        try {
            if (values.primaryPhoneNumber.length > 12 || values.primaryPhoneNumber.length < 5) {
                message.destroy()
                message.error("Invalid Phone Number")
                return
            }
            if (values.dateOfBirth === undefined) {
                const date = this.currentDate()
                values = { ...values, dateOfBirth: date.format("YYYY-MM-DD") }
            }

            if (!values.latitude && values.longitude) {
                message.destroy()
                message.error("Enter latitude")
                return
            } else if (values.latitude && !values.longitude) {
                message.destroy()
                message.error("Enter longitude")
                return
            }

            let requestData = {
                'firstName': values.firstName,
                'fullName': values.firstName,
                'specialCode': values.storeCode,
                'userStatus': values.active ? "ACTIVE" : "INACTIVE",
                'address1': values.address,
                'phoneNumber': values.primaryPhoneNumber,
                'approvalStatus': 'APPROVED',
                'latitude': values.latitude ? values.latitude : null,
                'longitude': values.longitude ? values.longitude : null,
                'township': {
                    'id': values.townshipId,
                },
                "accounts": [
                    {
                        "loginId": values.storeCode,
                        "primaryPhoneNumber": values.primaryPhoneNumber,
                        "password": values.password
                    }
                ],
                'partnership': {
                    id: null
                }
            }
            const response = await ApiInstance(Api.partnership_store, HTTP_METHOD.POST, MEDIA_TYPE.JSON, requestData)
            if (response.status === 200) {
                this.props.navigate(CustomPath.partnership_store)
            }
        } catch (error) {
            console.log(error);
        }
        this.setState({
            isLoading: false
        })
    }

    disabledDate = (current) => {
        const eighteenYearsAgo = dayjs().subtract(18, 'year');
        return current && current > eighteenYearsAgo;
    };

    currentDate = () => {
        return dayjs().subtract(18, 'year')
    }

    handleDivisionSelect = (value) => {
        const { township_collection_map } = this.state
        this.setState({
            township_collection: township_collection_map[value],
        })
        this.inputRef.current.setFieldsValue({ townshipId: null });
    }
    onError = (error) => {
        console.log(error)
    }


    render() {
        const { isLoading, division_collection, township_collection } = this.state
        return (
            <>
                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        <Breadcrumb
                            items={[
                                {
                                    title: `${i18next.t('home')}`,
                                },
                                {
                                    title: <Link to={CustomPath.partnership_store}>{`${i18next.t('store')}`}</Link>,
                                },
                                {
                                    title: `${i18next.t('form')}`,
                                }
                            ]}
                        /></Col>
                    <Col span={1} />
                </Row>

                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        <NJVCard
                            bordered={false}
                            title={`${i18next.t('partnership_store_form')}`}
                            extra={
                                <NJVButton type="primary" onClick={() => this.props.navigate(CustomPath.partnership_store)} text={`${i18next.t("back")}`} />
                            }
                        >
                            <Form
                                layout="vertical"
                                ref={this.inputRef}
                                initialValues={{ gender: 'MALE', active: true }}
                                onFinish={this.savePartnershipAdmin}
                            >
                                <Row gutter={[16, 16]}>
                                    <Col span={12} >
                                        <Form.Item name="firstName" label={`${i18next.t('store_name')}`} rules={[{ required: true, message: '*(requried)' }]}>
                                            <NJVInput size="large" placeholder={`${i18next.t('enter_store_name')}`} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="primaryPhoneNumber"
                                            label={`${i18next.t('phone_number')}`}
                                            rules={[
                                                { required: true, message: '*(requried)' },
                                                {
                                                    validator: PhoneNumberValidator,
                                                },
                                            ]}>
                                            <NJVInput size="large" placeholder={"979xxxxxxxx"} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} >
                                        <Form.Item name="storeCode" label={`${i18next.t('store_code')}`} rules={[{ required: true, message: '*(requried)' }, {
                                            pattern: /^[a-zA-Z0-9]+$/, message: 'Store Code must contain only letters and numbers'
                                        }]}>
                                            <NJVInput size="large" placeholder={`${i18next.t('enter_store_code')}`} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} >
                                        <Form.Item name="password" label={`${i18next.t('password')}`}
                                            rules={[{ required: true, message: '*(requried)' },
                                            { validator: AdminPasswordValidator }]} style={{ cursor: 'pointer' }}>
                                            <NJVInput placeholder={`${i18next.t('enter_password')}`} ispasswordinput={true} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="divisionId" label={`${i18next.t('division_state')}`} rules={[{ required: true, message: '*(requried)' }]}>
                                            <NJVSelect
                                                className="my-location-selector"
                                                placeholder={`${i18next.t('choose_division')}`}
                                                onChange={this.handleDivisionSelect}
                                                options={
                                                    division_collection && division_collection.map((division) => {
                                                        return {
                                                            value: division.id,
                                                            label: division.name,
                                                            type: 'division'
                                                        }
                                                    })
                                                }
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item name="townshipId" label={`${i18next.t('township_city')}`} rules={[{ required: true, message: '*(requried)' }]}>
                                            <NJVSelect
                                                className="my-location-selector"
                                                placeholder={`${i18next.t('choose_township')}`}
                                                onChange={this.handleTownshipSelect}
                                                options={
                                                    township_collection && township_collection.map((township) => {
                                                        return {
                                                            value: township.id,
                                                            label: township.name,
                                                            type: 'division'
                                                        }
                                                    })
                                                }
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={24} >
                                        <Form.Item name="address" label={`${i18next.t('store_address')}`} rules={[{ required: true, message: '*(requried)' }]}>
                                            <NJVInput size="large" istextareainput
                                                style={{ height: 60 }} placeholder={`${i18next.t('enter_store_address')}`} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} >
                                        <Form.Item name="latitude" label={`${i18next.t('lattitude')}`} rules={[{ validator: NumberAndDecimelPointValidatorNullable }]}>
                                            <NJVInput size="large" placeholder={`${i18next.t('enter_lattitude')}`} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} >
                                        <Form.Item name="longitude" label={`${i18next.t('longitude')}`} rules={[{ validator: NumberAndDecimelPointValidatorNullable }]}>
                                            <NJVInput size="large" placeholder={`${i18next.t('enter_longitude')}`} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item
                                            name="active"
                                            valuePropName="checked">
                                            <Checkbox>Active</Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Form.Item style={{ textAlign: 'end' }}>
                                    <NJVButton type="primary" htmlType="submit" loading={isLoading} text={`${i18next.t('create')}`} />
                                </Form.Item>
                            </Form>
                        </NJVCard>
                    </Col>
                    <Col span={1} />
                </Row>
            </>
        );
    }
}

export default compose(withRouter)(PartnershipStoreForm)