import { compose } from "@reduxjs/toolkit";
import { Col, DatePicker, Divider, Image, List, Pagination, Row, Select, Skeleton, Tag, message } from "antd";
import dayjs from 'dayjs';
import i18next from "i18next";
import React from "react";
import { FormLabelOptional, TitleLevel3 } from "../../component/GeneralComponent";
import { NJVButton, NJVCard, NJVDatePicker, NJVInput, NJVSelect, NJVSpacer } from "../../component/core-component";
import { Constant } from "../../core/constant";
import '../../css/order-history.css';
import Api from "../../network/api";
import { ApiInstance } from "../../network/api-manager";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";
import { Link } from "react-router-dom";

const { Option } = Select
const { RangePicker } = DatePicker;

const monthMap = {
    '0': 'January',
    '1': 'February',
    '2': 'March',
    '3': 'April',
    '4': 'May',
    '5': 'June',
    '6': 'July',
    '7': 'August',
    '8': 'September',
    '9': 'October',
    '10': 'November',
    '11': 'December',
}

const PENDING_PICK_UP = 'Pending Pickup'
const ARRIVED_AT_SORTING_HUB = 'Arrived at Sorting Hub'
const EN_ROUTE_TO_SORTING_HUB = 'En-route to Sorting Hub'
const ON_VEHICLE_FOR_DELIVERY = 'On Vehicle for Delivery'
const COMPLETED = 'Delivered'
const PENDING_RESCHEDULE = 'Pending Reschedule'
const RETURNED_TO_SENDER = 'Returned to Sender'
const CANCELLED = 'Cancelled'


const eventMap = {
    'PENDING_PICK_UP': 'Pending Pickup',
    'ARRIVED_AT_SORTING_HUB': 'Arrived at Sorting Hub',
    'EN_ROUTE_TO_SORTING_HUB': 'En-route to Sorting Hub',
    'ON_VEHICLE_FOR_DELIVERY': 'On Vehicle for Delivery',
    'COMPLETED': 'Delivered',
    'PENDING_RESCHEDULE': 'Pending Reschedule',
    'RETURNED_TO_SENDER': 'Returned to Sender',
    'CANCELLED': 'Cancelled'
}

const orderStatusList = [
    {
        key: 1,
        label: PENDING_PICK_UP,
        value: 'PENDING_PICK_UP'
    }, {
        key: 2,
        label: ARRIVED_AT_SORTING_HUB,
        value: 'ARRIVED_AT_SORTING_HUB'
    }, {
        key: 3,
        label: EN_ROUTE_TO_SORTING_HUB,
        value: 'EN_ROUTE_TO_SORTING_HUB'
    }, {
        key: 4,
        label: ON_VEHICLE_FOR_DELIVERY,
        value: 'ON_VEHICLE_FOR_DELIVERY'
    }, {
        key: 5,
        label: COMPLETED,
        value: 'COMPLETED'
    }, {
        key: 6,
        label: PENDING_RESCHEDULE,
        value: 'PENDING_RESCHEDULE'
    }, {
        key: 7,
        label: RETURNED_TO_SENDER,
        value: 'RETURNED_TO_SENDER'
    }, {
        key: 7,
        label: CANCELLED,
        value: 'CANCELLED'
    },
]

const empty_history_image = require('../../asset/shipper_history_empty.png')
class OrderHistory extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            backPage: props.location.state?.page || 1,
            filterData: {},
            order_history_collection: [],
            historyMap: {},
            titleList: [],
            isDataFetching: true,
            totalPage: 0,
            pageSize: Constant.pageSize,
            page: 1,
            orders_status_list: []
        }
    }

    componentDidMount() {
        this.fetchOrderStatus()
        let urlParam = new URLSearchParams(window.location.search);
        let urlPage = urlParam.get('page');
        if (urlPage) {
            const currentUrl = window.location.href;
            const index = currentUrl.indexOf('?');
            const searchParams = new URLSearchParams(currentUrl.substring(index))
            let filterData = {};
            for (let [key, value] of searchParams.entries()) {
                if (key !== 'page' && value !== null && value !== undefined && value !== 'null' && value !== 'undefined') {
                    filterData[key] = value
                }
            }
            if (filterData?.fromDate && filterData?.toDate) {
                filterData = { ...filterData, fromDate: dayjs(filterData?.fromDate), toDate: dayjs(filterData?.toDate) }
            }

            if (filterData['fromDate-toDate']) {
                filterData['fromDate-toDate'] = [dayjs(filterData?.fromDate), dayjs(filterData?.toDate)];
            }

            if (filterData?.storeId) {
                filterData = { ...filterData, storeId: +filterData?.storeId }
            }
            this.setState({
                filterData
            }, () => this.fetchData(urlPage))
        } else {
            urlPage = 1;
            urlParam.set('page', urlPage);
            const currentUrl = new URL(window.location.href);
            const newUrl = `${currentUrl.pathname}?${urlParam.toString()}`;
            window.history.replaceState({}, '', newUrl);
            this.fetchData(urlPage);
        }
    }

    fetchOrderStatus = async () => {
        try {
            var params = {
                'enumType': 'ORDER_STATUS'
            }
            const response = await ApiInstance(Api.enum_filter, HTTP_METHOD.GET, MEDIA_TYPE.JSON, params)
            if (response) {
                let temp = []
                response.map((res) => {
                    temp.push({
                        value: res.key,
                        label: res.value,
                    });
                })
                this.setState({
                    orders_status_list: temp
                })
            }
        } catch (error) {
        }
    }

    fetchData = async (pageNumber) => {
        let { pageSize, page, filterData } = this.state
        try {
            this.setState({
                isDataFetching: true
            })
            let params = {
                page: pageNumber ? pageNumber - 1 : page - 1,
                size: pageSize
            }
            if (filterData) {
                if ((filterData.fromDate && !filterData.toDate) || (!filterData.fromDate && filterData.toDate)) {
                    message.destroy()
                    message.error("Invalid date")
                    return
                }
                if (filterData.fromDate > filterData.toDate) {
                    message.destroy()
                    message.error("Invalid date")
                    return
                }

                if (filterData.fromDate) {
                    filterData = { ...filterData, 'fromDate': filterData.fromDate.format("YYYY-MM-DD") }
                }
                if (filterData.toDate) {
                    filterData = { ...filterData, 'toDate': filterData.toDate.format("YYYY-MM-DD") }
                }
                if (filterData.recipientPhoneNumber) {
                    const updatedValue = filterData.recipientPhoneNumber.charAt(0) === '0' ? filterData.recipientPhoneNumber.slice(1) : filterData.recipientPhoneNumber;
                    filterData = { ...filterData, recipientPhoneNumber: updatedValue }
                }

                if (filterData['fromDate-toDate']) {
                    filterData['fromDate-toDate'] = `${dayjs(filterData['fromDate-toDate'][0]).format("YYYY-MM-DD")}&${dayjs(filterData['fromDate-toDate'][1]).format("YYYY-MM-DD")}`;
                } else {
                    filterData = { ...filterData, 'fromDate': null, 'toDate': null }
                }

                params = {
                    ...params,
                    ...filterData,
                    page: filterData.page ? parseInt(filterData.page) - 1 : params.page,
                }
            }

            const response = await ApiInstance(Api.order_history, HTTP_METHOD.GET, MEDIA_TYPE.JSON, params)
            if (response && response.content) {
                var historyMap = {}
                var titleList = []
                response.content.map(history => {
                    const dayjsDate = dayjs(history.createdDate);
                    var title = monthMap[dayjsDate.month()] + " " + dayjsDate.year()

                    let lastDate = ''
                    if (history && history.orderEvents && history.orderEvents.length != 0) {
                        let lastEvent = history.orderEvents[history.orderEvents.length - 1]
                        if (lastEvent.timestamp) {
                            const eventTime = dayjs(lastEvent.timestamp, 'YYYY-MM-DD HH:mm:ss');
                            if (eventTime) {
                                lastDate = eventTime.format('YYYY-MMM-DD hh:mm A')
                            }
                        }
                    }

                    history = { ...history, createdDate: dayjsDate.format('DD MMM, YYYY hh:mm A'), orderStatus: eventMap[history.orderStatus], lastUpdateOn: lastDate }

                    if (historyMap[title]) {
                        var currentHistory = historyMap[title]
                        currentHistory = [...currentHistory, history]
                        historyMap[title] = currentHistory
                    } else {
                        var currentHistory = [history]
                        historyMap[title] = currentHistory
                        titleList.push(title)
                    }
                })
                this.setState({
                    historyMap: historyMap,
                    titleList: titleList,
                    isDataFetching: false,
                    page: pageNumber ? pageNumber : page,
                    totalPage: response.totalElements,

                })
            }
        } catch (error) {
            message.error("An error occurred while fetching order history. Please try again later.");
        }
    }

    search = () => {
        let { filterData } = this.state;
        const searchParams = new URLSearchParams()

        if (filterData) {
            searchParams.append('page', 1);

            if (filterData.fromDate) {
                filterData = { ...filterData, fromDate: filterData.fromDate.format("YYYY-MM-DD") }
            }
            if (filterData.toDate) {
                filterData = { ...filterData, toDate: filterData.toDate.format("YYYY-MM-DD") }
            }
            if (filterData['fromDate-toDate']) {
                filterData['fromDate-toDate'] = `${dayjs(filterData['fromDate-toDate'][0]).format("YYYY-MM-DD")}&${dayjs(filterData['fromDate-toDate'][1]).format("YYYY-MM-DD")}`;
            } else {
                filterData = { ...filterData, 'fromDate': null, 'toDate': null }
            }

            for (let key in filterData) {
                if (key !== 'page' && filterData[key] !== null && filterData[key] !== undefined && filterData[key] !== 'null' && filterData[key] !== 'undefined' && filterData[key] !== '') {
                    searchParams.append(key, filterData[key])
                }
            }

        }

        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        url.search = searchParams.toString();

        this.setState({
            isSearchingData: true
        })
        this.fetchData(1)
        window.history.pushState({}, '', url)
    }


    // routToNotificationDetail = (trackingNumber) => {
    //     let { filterData, page } = this.state;
    //     if (filterData.fromDate) {
    //         filterData = { ...filterData, fromDate: filterData.fromDate.format("YYYY-MM-DD") }
    //     }
    //     if (filterData.toDate) {
    //         filterData = { ...filterData, toDate: filterData.toDate.format("YYYY-MM-DD") }
    //     }
    //     const searchParams = new URLSearchParams();
    //     for (let key in filterData) {
    //         if (filterData[key] !== null && filterData[key] !== undefined && filterData[key] !== 'null' && filterData[key] !== 'undefined' && filterData[key] !== '') {
    //             searchParams.append(key, filterData[key]);
    //         }
    //     }

    //     const currentUrl = `${CustomPath.order_history}?${searchParams.toString()}`

    //     const data = {
    //         id: trackingNumber,
    //         route: currentUrl,
    //     }
    //     this.props.navigate(
    //         CustomPath.order_detail,
    //         { state: { data: data } }
    //     );
    // }

    updateFilterData = (key, value) => {
        let { filterData } = this.state;

        filterData = {
            ...filterData,
            [key]: value
        }

        if (key === 'fromDate-toDate' && value) {
            filterData = { ...filterData, fromDate: value[0], toDate: value[1], 'fromDate-toDate': value }
            this.setState({
                filterData
            })
        }

        this.setState({
            filterData
        })
    }

    pagination = (page, size) => {
        const urlParam = new URLSearchParams(window.location.search);
        const urlPage = urlParam.get('page');
        let newUrl;
        if (urlPage) {
            const currentUrl = window.location.href;
            let replace_url = currentUrl.toString();

            newUrl = replace_url.replace(/page=\d+/, `page=${page}`);
        } else {
            urlParam.set('page', page);
            const currentUrl = new URL(window.location.href);
            newUrl = `${currentUrl.pathname}?${urlParam.toString()}`;
        }

        window.history.pushState({}, '', newUrl)
        this.fetchData(page)
        this.setState({
            page: page - 1
        })
    }

    render() {
        const { isDataFetching, historyMap, titleList, totalPage, page, filterData, pageSize, orders_status_list } = this.state
        const currentSearchUrl = window.location.search;

        return (
            <Row>
                <Col span={1} />
                <Col span={22}>
                    <Row>
                        <Col span={24}>
                            <TitleLevel3 label={i18next.t("order_history")} />
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 10 }}>
                        <Col span={24}>
                            <NJVCard
                                style={{ paddingTop: 15, paddingBottom: 15 }}
                                bordered={false}
                            >
                                <Row gutter={[16, 16]}>
                                    <Col lg={6} md={6} sm={24} xs={24}>
                                        <FormLabelOptional label={i18next.t("tracking_number")} />
                                        <NJVInput value={filterData.trackingNumber} size="large" placeholder={i18next.t("tracking_number")} onChange={(event) => this.updateFilterData('trackingNumber', event.target.value)} />
                                    </Col>
                                    <Col span={6} md={6} sm={24} xs={24}>
                                        <FormLabelOptional label="Created Date(from-to)" />
                                        <RangePicker size="large" style={{ width: '100%', background: '#fff', border: 'none' }}
                                            allowClear
                                            value={filterData['fromDate-toDate']}
                                            onChange={(value) => this.updateFilterData('fromDate-toDate', value)} />
                                    </Col>
                                    <Col lg={6} md={6} sm={24} xs={24}>
                                        <FormLabelOptional label={i18next.t("receiver_name")} />
                                        <NJVInput size="large" value={filterData.recipientName} placeholder={i18next.t("receiver_name")} onChange={(event) => this.updateFilterData('recipientName', event.target.value)} />
                                    </Col>
                                    <Col lg={6} md={6} sm={24} xs={24}>
                                        <FormLabelOptional label={i18next.t("receiver_phone_number")} />
                                        <NJVInput size="large" value={filterData.recipientPhoneNumber} placeholder={i18next.t("receiver_phone_number")} onChange={(event) => this.updateFilterData('recipientPhoneNumber', event.target.value)} />
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12}>
                                        <FormLabelOptional label={i18next.t("order_status")} />
                                        <NJVSelect
                                            value={filterData.orderStatus}
                                            onChange={(value) => this.updateFilterData('orderStatus', value)}
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            className='my-location-selector'
                                            placeholder={i18next.t("order_status")}
                                            options={orders_status_list}
                                        />
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12}>
                                        <FormLabelOptional label="&nbsp;" />
                                        <NJVButton size="large"
                                            style={{ width: '100%' }}
                                            type="primary" onClick={() => this.search()} text={i18next.t("search")} />
                                    </Col>
                                </Row>
                            </NJVCard>
                        </Col>
                    </Row>
                    <Row>
                        {
                            isDataFetching ?
                                <Col span={24}>
                                    <Skeleton active />
                                </Col>
                                :
                                <>
                                    {
                                        titleList && titleList.length !== 0 ?
                                            <>
                                                <Col span={24}>
                                                    {
                                                        titleList.map((value, index) => {
                                                            var historyList = historyMap[value]

                                                            return <Row key={index}>
                                                                <Col span={24}>
                                                                    <NJVCard
                                                                        style={{ padding: 0, marginBottom: 10 }}
                                                                        bordered={false}
                                                                        title={value}
                                                                    >
                                                                        <List
                                                                            itemLayout="horizontal"
                                                                            dataSource={historyList}
                                                                            renderItem={(item, index) => (
                                                                                <Link to={CustomPath.order_detail} state={{ data: { id: item.trackingNumber, route: `${CustomPath.order_history}${currentSearchUrl}` } }}>
                                                                                    <List.Item>
                                                                                        <Row style={{ width: '100%' }}>
                                                                                            <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                                <span style={{ fontSize: 15, fontWeight: 500 }}>{item.orderType}</span>
                                                                                                {
                                                                                                    item.lastUpdateOn ?
                                                                                                        <span> Last Updated On : {item.lastUpdateOn}</span>
                                                                                                        :
                                                                                                        <></>
                                                                                                }

                                                                                            </Col>
                                                                                            <Col span={24} style={{}}>
                                                                                                <span>{item.recipientName}</span><br />
                                                                                                <span>{item.recipientPhoneNumber}</span>
                                                                                            </Col>
                                                                                            <Col>
                                                                                                <NJVSpacer height={50} />
                                                                                            </Col>
                                                                                            <Col span={24}>

                                                                                                {
                                                                                                    item.ninjaCoreOrderStatus === 'Cancelled' || item.ninjaCoreOrderStatus === 'CANCELLED' ?
                                                                                                        <Tag style={{ marginRight: 0 }} color="#FF0000">{item.ninjaCoreOrderStatus}</Tag>
                                                                                                        :
                                                                                                        <Tag style={{ marginRight: 0 }} color="#108ee9">{item.ninjaCoreOrderStatus}</Tag>
                                                                                                }

                                                                                            </Col>
                                                                                        </Row>
                                                                                    </List.Item>
                                                                                </Link>
                                                                            )}
                                                                        />

                                                                    </NJVCard>
                                                                </Col>

                                                            </Row>
                                                        })
                                                    }
                                                </Col>
                                                <Col span={24} style={{ marginTop: 20, textAlign: 'right' }}>
                                                    <Pagination
                                                        showSizeChanger={false}
                                                        current={page}
                                                        defaultPageSize={pageSize}
                                                        total={totalPage}
                                                        onChange={this.pagination} />
                                                </Col>
                                            </>
                                            :
                                            <Row style={{ marginTop: 50, justifyContent: 'center', width: '100%' }}>
                                                <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Image
                                                        width={200}
                                                        preview={false}
                                                        src={empty_history_image} />
                                                </Col>
                                                <Col span={24} style={{ textAlign: 'center', marginTop: 20 }}>
                                                    {
                                                        filterData ?
                                                            <div style={{ fontSize: 17 }}>
                                                                No order found for your search request.
                                                            </div>
                                                            :
                                                            <>
                                                            </>

                                                    }
                                                </Col>
                                            </Row>
                                    }

                                </>

                        }
                    </Row>
                </Col>
            </Row >
        )
    }
}

export default compose(withRouter)(OrderHistory);