const Api = {
    host: process.env.REACT_APP_SERVER_URL,
    // host: 'https://nvmproductionenv-4a3c4fe9dc5e.herokuapp.com',

    login: 'api/auth/login',
    refresh_token: 'api/auth/refresh_token',

    partnership_profile: 'partnership/store/profile',

    partnership_store: 'partnership/admin/store',
    partnership_store_filter: 'partnership/admin/store/filter',
    partnership_store_admin_order_history: 'order/partnership/history',
    partnership_admin_report: 'partnership/report',
    partnership_store_report: 'partnership/store/report',
    partnership_storename: 'partnership/store-select-items',
    partner_export: 'partnership/excel-export',
    division_get_all: 'division/getAll',

    township_filter: 'township/filter',
    township: 'township',
    township_filter_active: 'township/enable',

    bus_gate_by_receiver_and_sender: 'bus-gate/findByAvailableTownship',
    check_bus_gate_service_available: 'bus-gate/checkBusGateByTownship',
    notification_history_filter: 'notification_history/filter_by_currentId',
    notification_history: 'notification_history',

    order: 'order',
    order_create: 'order/create_order',
    order_history: 'order/history',
    order_get_by_tracking_number: 'order/get_order_by_tracking_number',
    get_price: 'zone/get_price_script',

    order_download_excel: 'partnership/order-history-excel-export',
    enum_filter: 'enum/filter',

    receive: 'order-pickup-drop-off/pickup-from-delivery',
    pickup: 'order-pickup-drop-off/deliver-to-customer'
};

export default Api;
