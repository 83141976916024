import { compose } from '@reduxjs/toolkit';
import { Checkbox, Col, Form, Image, Radio, Row, message } from 'antd';
import i18next from 'i18next';
import cookies from 'js-cookie';
import React, { Component } from 'react';
import { NJVButton, NJVCard, NJVInput } from '../../component/core-component';
import { SvgLockFilled, SvgMobileFilled, SvgMyanmarFlag, SvgStoreOutlined, SvgUKFlag } from '../../component/custom-svgIcon';
import Theme from '../../component/theme';
import { LOC_storeToken, LOC_storeUserType } from '../../core/local-storage';
import '../../css/login.css';
import { Authenticate } from '../../network/auth-provider';
import withRouter from '../../network/with-router';
import { updateAccessToken } from '../../redux/auth-slice';
import CustomPath from '../../routes/custom-path';

const logo = require('../../asset/partner-logo.png');

class LoginPage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      storeCode: '',
      visiblePassword: false,
      loggingIn: false,
      currentLanguage: 'en',
      loginUser: 1
    }
    this.formRef = React.createRef()
  }

  componentDidMount() {
    const currentLanCode = cookies.get("i18next") || "en"
    i18next.changeLanguage(currentLanCode)
    this.setState({
      currentLanguage: currentLanCode
    })
  }

  login = async (values) => {
    
    message.destroy()
    if ((values.storeCode === undefined || values.storeCode === '') && (values.password === undefined || values.password === '')) {
      if (this.state.loginUser === 1) {
        message.error("Please Enter Phone Number & Password")
      } else {
        message.error("Please Enter Store Code & Password")
      }
    } else if (values.storeCode === undefined || values.storeCode === '') {
      message.error("Please Enter Phone number")
    } else if (values.password === undefined || values.password === '') {
      message.error("Please Enter Password")
    } else {
      this.setState({
        loggingIn: true
      })
      try {
        let userType = 'SP_ADMIN'
        if (values.userType === 2) {
          userType = 'SP_STAFF'
        }
        const response = await Authenticate(userType, values.storeCode, values.password);
        if (response && response.status === 200) {
          LOC_storeToken(response.data);
          LOC_storeUserType(userType)
          this.props.dispatch(updateAccessToken(response.data));
          this.props.navigate(CustomPath.dashboard)
        } else if (response && response.response.status === 401) {
          message.error("Store Code or Password wrong!")
          this.loginFail()
        }
        if (response && response.response.status === 500) {
          if (response.response.data) {
            message.error(response.response.data.title)
          }
          this.loginFail()
        }
      } catch (error) {
        console.log(error);
        
        this.loginFail()
      }
    }
  }

  loginFail = () => {
    this.formRef?.current?.setFieldsValue({ 'storeCode': '', 'password': '' })
    this.setState({
      loggingIn: false
    })
  }

  handleInputChange = (e) => {
    const inputValue = e.target.value;
    this.formRef.current.setFieldsValue({ storeCode: inputValue })
  }

  handleRadioChange = (e) => {
    this.setState({
      loginUser: e.target.value
    })
  }

  showPassword = value => {
    this.setState({ visiblePassword: value.target.checked })
  }

  changeLanguage = () => {
    const { currentLanguage } = this.state
    if (currentLanguage == 'en') {
      i18next.changeLanguage("mm")
      this.setState({
        currentLanguage: 'mm'
      })
    } else if (currentLanguage === 'mm') {
      i18next.changeLanguage("en")
      this.setState({
        currentLanguage: 'en'
      })
    }
  }

  render() {
    const { visiblePassword, currentLanguage, loginUser, loggingIn } = this.state
    return (
      <>
        <div style={{ cursor: 'pointer', position: 'fixed', right: 15, top: 15, zIndex: 1 }} onClick={() => this.changeLanguage()}>
          {
            currentLanguage === 'en' ? <SvgUKFlag width={35} height={35} /> : <SvgMyanmarFlag width={35} height={35} />
          }
        </div>
        <Row style={{ height: '100vh', alignItems: 'center' }}>

          <Col span={24}>
            <Row style={{ height: '100vh', alignContent: 'center' }}>
              <Col span={8} />
              <Col span={8}>
                <NJVCard className="login-card" bordered={false}>
                  <Row>
                    <Col span={24} style={{ marginBottom: 50 }}>
                      <Image
                        width={200}
                        src={logo}
                        preview={false}
                      />
                    </Col>
                  </Row>
                  <Form
                    name='normal_login'
                    className='login-form'
                    onFinish={this.login}
                    initialValues={{ 'userType': 1 }}
                    ref={this.formRef}>
                    <Form.Item name="userType">
                      <Radio.Group onChange={this.handleRadioChange}>
                        <Radio value={1}>Admin</Radio>
                        <Radio value={2}>Staff</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item
                      style={{ marginBottom: 30 }}
                      name='storeCode'
                    >

                      {
                        loginUser === 1 ? <NJVInput
                          className="login-box"
                          style={{ background: '#f5f5f5', border: 'none', borderRadius: 12 }}
                          prefix={<SvgMobileFilled width={20} height={20} color={"#898989"} />}
                          placeholder={i18next.t("phone_number")}
                          onChange={this.handleInputChange}

                        /> : loginUser === 2 ? <NJVInput className='login-box' style={{ background: '#f5f5f5', border: 'none', borderRadius: 12 }} prefix={<SvgStoreOutlined width={17} height={17} color={"#898989"} />}
                          placeholder={i18next.t("store_code")} onChange={this.handleInputChange} /> : <></>
                      }
                    </Form.Item>
                    <Form.Item
                      style={{ marginBottom: 10 }}
                      name="password"
                    >
                      <NJVInput
                        className="login-box"
                        style={{ background: '#f5f5f5', border: 'none', borderRadius: 12 }}
                        prefix={<SvgLockFilled width={20} height={20} color={"#898989"} />}
                        type={visiblePassword ? "text" : "password"}
                        placeholder={i18next.t("password")}
                      />
                    </Form.Item>
                    <Row>
                      <Col span={24} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Checkbox
                          style={{ marginBottom: 30 }}
                          onChange={this.showPassword}>
                          {i18next.t("show_password")}
                        </Checkbox>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24} className='login-form-button'>
                        <Form.Item>
                          <NJVButton size="large" buttonprimary={Theme.colors.primary} type="primary" htmlType="submit" loading={loggingIn} text={i18next.t('login')} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </NJVCard>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    )
  }
}

export default compose(withRouter)(LoginPage)