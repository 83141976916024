import CustomPath from "../routes/custom-path";

export const NJVPhotoURL = {
    logo_url: 'https://d1jejw4ssai0l5.cloudfront.net/NinjaContentPics/Logo.png',
    landingPage_url: 'https://d1jejw4ssai0l5.cloudfront.net/NinjaContentPics/WebLoginPage.png',
    qr_url: 'https://d1jejw4ssai0l5.cloudfront.net/NinjaContentPics/QR.png',
    profile_url: 'https://d1jejw4ssai0l5.cloudfront.net/NinjaContentPics/Profile.png',
    nodata_url: 'https://d1jejw4ssai0l5.cloudfront.net/NinjaContentPics/NoData.png'
}

export const SIDE_MENU = [
    { route: CustomPath.order_create, name: 'order_create' },
    { route: CustomPath.order_history, name: "order_history" },
    // { route: CustomPath.receive, name: "receive"},
    // { route: CustomPath.customer_pickup, name: "customer_pickup"},
    { route: CustomPath.notification_history, name: "notification_history" },
    { route: CustomPath.none_service, name: "none_service_item"}
]

export const ADMIN_SIDE_MENU = [
    { route: CustomPath.partnership_store, name: 'store' },
    { route: CustomPath.partnership_store_admin_order_history, name: "order_history" },
    // { route: CustomPath.report, name: "report" }
]

export const ADMIN = 'SP_ADMIN'
export const STAFF = 'SP_STAFF'


export const Constant = {
    pageSize: 20
}
