const partner = '/v1'
const CustomPath = {
    app: '/',


    partnership_store: partner + '/store',
    partnership_store_create: partner + '/store/create',
    partnership_store_update: partner + '/store/update',
    partnership_store_update_param: partner + '/store/update/:id',

    partnership_store_admin_order_history: partner + '/store/order-history',


    notification_history: partner + '/notification-history',


    dashboard: partner + '/dashboard',
    profile: partner + '/profile',

    notification: partner + '/notification',
    notification_detail: partner + '/notification/detail',

    order: partner + '/order',
    order_create: partner + '/order_create',
    order_confirmation: partner + '/order_create/order_confirmation',
    order_return: partner + '/order_return',
    order_update: partner + '/order_update',
    order_history: partner + '/order_history',
    order_detail: partner + '/order_detail',
    order_detail_param: partner + '/order_detail/:id',
    order_awb: partner + '/awb',
    rom_order: partner + '/rom',
    rts_order: partner + '/rts-order',
    rts_order_detail: partner + '/rts-order/detial',

    user_address: partner + '/address',
    user_address_create: partner + '/address/create',
    user_address_update: partner + '/address/update',
    user_address_update_param: partner + '/address/update/:id',

    airwaybill: partner + '/air-way-bill',

    single_order_result: partner + '/single_order_detail',
    single_order_result_param: partner + '/single_order_detail/:id',

    login: '/',
    logout: "/logout",
    signUp: "/signUp",
    forgot_password: "/forgot_password",

    report: partner + '/report',

    none_service: partner + '/none-service-item',
    receive: partner + '/receive',
    customer_pickup: partner + '/customer-pickup'
}

export default CustomPath;