import { createSlice } from '@reduxjs/toolkit'

export const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        isFetchProfile: false,
        fullName: '',
        phoneNumber: '',
        shipperUniqueId: '',
        senderTownshipId: null,
        senderDivision: {}
    },
    reducers: {
        updateProfile: (state, {payload}) => {
            state.fullName = payload.fullName
            state.isFetchProfile = true
            state.phoneNumber = payload.phoneNumber
            if (payload.township !== null) {
                state.senderTownshipId = payload.township.id
            } 
            if(payload.township !== null){
                state.senderDivision = {
                    id: payload.township.division.id,
                    name: payload.township.division.name
                }
            }
        },
        clearProfile: (state, action) => {
            state.phoneNumber = ''
            state.fullName = ''
            state.shipperUniqueId = ''
            state.isFetchProfile = false
        }
    },
})

export const { updateProfile, clearProfile } = profileSlice.actions
export const fullName = (state) => state.profile.fullName
export const phoneNumber = (state) => state.profile.phoneNumber
export const senderTownshipId = (state) => state.profile.senderTownshipId
export const senderDivision = (state) => state.profile.senderDivision
export const isFetchProfile = (state) => state.profile.isFetchProfile
export default profileSlice.reducer
