import { EditOutlined, PlusCircleFilled } from "@ant-design/icons";
import { Col, Row, Skeleton, Table, Tag } from "antd";
import i18next from "i18next";
import React from "react";
import { compose } from "redux";
import { NJVButton, TitleLevel3 } from "../../component/core-component";
import { Constant } from "../../core/constant";
import '../../css/partnership-store-page.css';
import Api from "../../network/api";
import { ApiInstance } from "../../network/api-manager";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";


class PartnershipStorePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageSize: Constant.pageSize,
            totalPagination: 0,
            page: 1,
            searchName: '',
            searchPhone: '',
            openModal: false,
            openCreateModal: false,
            openUpdateModal: false,
            activeStatus: true,
            updateForm: {},
            updateDivision: {},
            isDataFetching: false,
            storeCreateDrawer: false,
            is_saving_store: false,
        }
        this.inputRef = React.createRef();
        this.updateRef = React.createRef();
    }

    componentDidMount() {
        this.fetchData()
    }
    fetchData = async (pageNumber) => {
        const { page, searchName, searchPhone, pageSize } = this.state
        this.setState({
            isDataFetching: true
        })
        try {

            var params = {
                name: searchName,
                phone: searchPhone,
                size: pageSize,
                page: pageNumber ? pageNumber - 1 : page - 1,
            }
            const response = await ApiInstance(Api.partnership_store_filter, HTTP_METHOD.GET, MEDIA_TYPE.GET, params)
            if (response) {
                this.setState({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page
                })
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false
        })
    }

    changeSearchName = (event) => {
        this.setState({
            searchName: event.target.value
        })
    }

    changeSearchPhone = (event) => {
        this.setState({
            searchPhone: event.target.value
        })
    }

    search = () => {
        const { page } = this.state
        this.fetchData(page)
    }

    handlePaginationChange = (pageNumber, pageSize) => {
        this.fetchData(pageNumber);
    }

    gotoStoreUpdate = (id) => {
        let data = {
            storeId: id
        }
        let route = CustomPath.partnership_store_update
        this.props.navigate(
            route,
            { state: { data: data } }
        );
    }

    render() {
        const { storeCreateDrawer, data, totalPagination, page, pageSize, isDataFetching, division_collection, township_collection, is_saving_store } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: `${i18next.t('no')}`,
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: `${i18next.t('store_name')}`,
                key: 'fullName',
                dataIndex: 'fullName'
            },
            {
                title: `${i18next.t('phone_number')}`,
                dataIndex: 'phoneNumber',
                key: 'phoneNumber'
            },
            {
                title: `${i18next.t('store_code')}`,
                dataIndex: 'specialCode',
                key: 'specialCode'
            },
            {
                title: `${i18next.t('location')}`,
                key: 'location',
                render: (store) => (
                    <>
                        {
                            store.latitude && store.longitude ?
                                <span>{store.latitude},{store.longitude}</span>
                                :
                                <></>
                        }
                    </>
                ),
            },
            {
                title: `${i18next.t('status')}`,
                key: 'active',
                render: (_, { userStatus }) => (
                    <>
                        {
                            (
                                userStatus === "ACTIVE" ? <Tag color="#87d068">{userStatus}</Tag> : <Tag color="#cd201f">{userStatus}</Tag>
                            )
                        }
                    </>
                ),

            },
            {
                title: `${i18next.t('action')}`,
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 60,
                render: (store) => (
                    <NJVButton size={'middle'} type="primary" shape="circle" onClick={() => this.gotoStoreUpdate(store.id)} text={<EditOutlined />} />
                ),
            },
        ]
        return (
            <>
                <Row style={{ marginBottom: "20px" }} gutter={[16, 16]}>
                    <Col span={1} />
                    <Col span={22}>
                        <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
                            <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <TitleLevel3 label={i18next.t('store')} />
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                                {/* <NJVAddButton handleClick={() => this.setState({ storeCreateDrawer: true })} /> */}
                                {/* <Link to={CustomPath.partnership_store_create}> */}
                                    <NJVButton
                                        type="primary"
                                        icon={<PlusCircleFilled />}
                                        onClick={() => this.props.navigate(CustomPath.partnership_store_create)}
                                        shape="round"
                                        text={`${i18next.t("create")}`}
                                    />
                                {/* </Link> */}
                            </Col>
                            {/* <Col span={24}>
                                <NJVInput
                                    onChange={this.changeSearchName}
                                    style={{ width: 200, background: '#f1f1f1', height: 40, marginRight: 8 }}
                                    placeholder="Search by name"
                                />
                                <NJVInput
                                    onChange={this.changeSearchPhone}
                                    style={{ width: 200, background: '#f1f1f1', height: 40, marginRight: 8 }}
                                    placeholder="Search by phone"
                                />
                                <Button onClick={() => this.search()} type="primary" style={{ borderRadius: 10, height: 40 }}>Search</Button>
                            </Col> */}
                            <Col span={24}>
                                {
                                    isDataFetching ?
                                        <Skeleton active />
                                        :
                                        <Table
                                            style={{ borderRadius: 10 }}
                                            pagination={{
                                                position: 'top' | 'bottom',
                                                total: totalPagination,
                                                current: page,
                                                onChange: this.handlePaginationChange,
                                                defaultPageSize: pageSize,
                                                showSizeChanger: false
                                            }}
                                            columns={columns}
                                            dataSource={data}
                                            rowClassName={getRowClassName}
                                        />
                                }
                            </Col>
                        </Row>

                    </Col>
                </Row>






            </>
        );
    }
}

export default compose(withRouter)(PartnershipStorePage)