import { PrinterOutlined } from '@ant-design/icons';
import { compose } from '@reduxjs/toolkit';
import { Button, Col, message, Modal, Row } from 'antd';
import dayjs from 'dayjs';
import i18next from 'i18next';
import Lottie from 'lottie-react';
import React, { Component } from 'react';
import ReactToPrint from 'react-to-print';
import animationData from '../../animate-lottie/animation_lnfk8gz0.json';
import FailAnimation from '../../animate-lottie/lottie-404.json';
import SuccessAnimation from '../../animate-lottie/lottie-success.json';
import { NJVButton, NJVCard, TitleLevel3 } from '../../component/core-component';
import '../../css/order_confirm.css';
import Api from '../../network/api';
import { ApiInstance } from '../../network/api-manager';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import withRouter from '../../network/with-router';
import CustomPath from '../../routes/custom-path';
import AirWayBillSlip from './airway-bill-slip';

const NJVLabel = ({ label }) => {
    return (
        <>
            <span style={{ fontSize: 13, fontWeight: '600', color: 'gray' }}>{label}</span>
            <br />
        </>)
}
const NJVValue = ({ value }) => {
    return (
        <>
            <span style={{ fontSize: 16, fontWeight: '500', color: 'black' }}>{value}</span>
            <br />
        </>)
}
const initState = 1
const successState = 2
const failState = 3
class orderConfirm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orderState: initState,
            order_object: props.location?.state?.orderData || {},
            openConfirmModal: false,
            fail_message_code: '076',
        }
        this.printButtonRef = React.createRef()
    }    

    confirm = () => {
        this.setState({
            openConfirmModal: true
        }, () => this.createOrderWithFormData());

    }

    clearAllUserOrder = () => {

        this.setState({
            userInput: { orderType: 1, deliveryFees: 0 },
        })
    }

    getValidDate = () => {
        const currentDate = dayjs();
        const targetTime = dayjs().set('hour', 13).set('minute', 0).set('second', 0);
        return currentDate.isBefore(targetTime) ? currentDate : currentDate.add(1, 'day');
    }

    createOrderWithFormData = async () => {
        const { senderName, senderPhoneNumber, additionalAddress, senderTownship, senderDivision, nrcFront, note, receiverName, receiverTownship, receiverPhoneNumber, addressDetail, deliveryFees, fail_message_code, productImage } = this.state.order_object;
        this.setState({
            orderState: initState
        })

        if (deliveryFees === 0) {
            message.destroy()
            message.error(`You can't confirm order if delivery fees 0`)
            return
        }

        try {
            const pickup_date = dayjs(this.getValidDate()).format('YYYY-MM-DD')
            let formData = new FormData()
            formData.append('pickUpDate', pickup_date)
            formData.append('isAdmin', false)
            formData.append('senderName', senderName)
            formData.append('senderPhoneNumber', senderPhoneNumber)
            if (nrcFront) {
                formData.append('senderNRCFrontImage', nrcFront)
            }
            formData.append('additionalAddress', `${additionalAddress},${senderTownship?.label},${senderDivision?.label}`)
            formData.append('townShipId', this.props.senderTownship)
            formData.append("receiverDTOS[0].receiverName", receiverName)
            formData.append("receiverDTOS[0].phoneNumber", receiverPhoneNumber)
            formData.append(`receiverDTOS[0].isFavourite`, false)
            formData.append("receiverDTOS[0].addressDetail", addressDetail)
            formData.append("receiverDTOS[0].orderType", 'Home Delivery')
            formData.append("receiverDTOS[0].paymentOption", 'Collect from customer')
            formData.append("receiverDTOS[0].isSameDay", false)
            formData.append("receiverDTOS[0].parcelAmount", 0)
            formData.append(`receiverDTOS[0].codAmount`, 0)
            formData.append("receiverDTOS[0].deliveryCharges", deliveryFees)
            formData.append(`receiverDTOS[0].note`, note && note !== undefined ? note : null)
            formData.append(`receiverDTOS[0].townShipId`, receiverTownship?.value)
            if (productImage) {
                formData.append(`receiverDTOS[0].productImage`, productImage);
            }
            const response = await ApiInstance(Api.order_create, HTTP_METHOD.POST, MEDIA_TYPE.FORM_DATA, formData)
            if (response && response.status === 200) {
                this.setState({
                    orderState: successState,
                    orderList: response?.data
                })
                this.clearAllUserOrder()
            }
        } catch (error) {
            let code = fail_message_code
            if (error && error.response && error.response.data && error.response.data.code) {
                console.log(error.response.data);
            }
            this.setState({
                orderState: failState,
                fail_message_code: code
            })
        }
    }

    GoToHistory = () => {
        this.setState({ openConfirmModal: false, orderState: initState })
        this.props.navigate(CustomPath.order_history)
    }
    airwaybill = () => {
        this.printButtonRef.current.click()
    }
    render() {
        const { order_object, openConfirmModal, orderState, fail_message_code, orderList } = this.state;

        return (
            <>
                <Modal
                    title={null}
                    centered
                    open={openConfirmModal}
                    onOk={() => this.createOrderWithFormData()}
                    footer={null}
                    closeIcon={null}
                >
                    <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            {
                                orderState === initState ?
                                    <>

                                        <Lottie
                                            style={{ height: '200px' }}
                                            animationData={animationData}
                                            loop={true}
                                            autoplay={true}
                                        />

                                        <div style={{ fontSize: 15, fontWeight: '500' }}>{i18next.t("msg_please_wait")}</div>
                                    </> :
                                    <></>
                            }
                            {
                                orderState === successState ?
                                    <>
                                        <Lottie
                                            style={{ height: '200px' }}
                                            animationData={SuccessAnimation}
                                            loop={false}
                                            autoplay={true}
                                        />
                                        <div style={{ marginTop: 30, fontSize: 15, fontWeight: '500' }}>{i18next.t("msg_order_create_success")}</div>
                                        <NJVButton type='text' icon={<PrinterOutlined />} onClick={() => this.airwaybill()} style={{ marginTop: 10, marginBottom: 10 }} text={i18next.t("msg_print_awb")} />
                                        <br />
                                        <NJVButton type='primary' onClick={() => this.GoToHistory()} text={i18next.t("msg_go_to_order_history")} />
                                    </>
                                    :
                                    <></>
                            }
                            {
                                orderState === failState ?
                                    <>
                                        <Lottie
                                            style={{ height: '200px' }}
                                            animationData={FailAnimation}
                                            loop={false}
                                            autoplay={true}
                                        />
                                        <div style={{ marginTop: 30, marginBottom: 20, fontSize: 15, fontWeight: '500' }}>
                                            {
                                                fail_message_code === '076' ?
                                                    <>
                                                        {i18next.t("msg_fail_to_create_order")}
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            fail_message_code === '077' ?
                                                                <>
                                                                    {i18next.t("msg_fail_to_create_order_077")}
                                                                </>
                                                                :
                                                                <>
                                                                    {i18next.t("msg_fail_to_create_order")}
                                                                </>
                                                        }
                                                    </>
                                            }



                                        </div>
                                        <NJVButton type='primary' onClick={() => this.setState({ openConfirmModal: false })} text="Yes" />
                                    </>
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </Modal>
                <Row>
                    <Col lg={6} md={4} xs={1} sm={1} />
                    <Col lg={12} md={16} xs={22} sm={22} >
                        <Row style={{alignItems: 'center'}}>
                            <Col span={12}>
                                <TitleLevel3 label={i18next.t("order_detail")} style={{ marginTop: 10,marginBottom: 20 }} />
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                                <NJVButton
                                    onClick={() => this.props.navigate(CustomPath.order_create, { state: { data: order_object}})}
                                    type="primary" text="Back" />
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                {/* <Card style={{ background: "#f5f5f5", boxShadow: 'none', width: '100%' }}> */}
                                <NJVCard>
                                    <Row gutter={[16, 16]}>
                                        <Col span={24} >
                                            <NJVLabel label={i18next.t("sender_name")} />
                                            <NJVValue value={order_object.senderName} />
                                        </Col>
                                        <Col span={24}>
                                            <NJVLabel label={i18next.t("sender_phone_number")} />
                                            <NJVValue value={order_object?.senderPhoneNumber} />
                                        </Col>
                                        <Col span={24}>
                                            <NJVLabel label={i18next.t("receiver_address")} />
                                            <NJVValue value={`${order_object?.additionalAddress}, ${order_object?.senderTownship?.label}, ${order_object?.senderDivision?.label}`} />
                                        </Col>
                                    </Row>
                                </NJVCard>
                            </Col>
                            <Col span={24}>
                                <NJVCard>
                                    <Row gutter={[16, 16]}>
                                        <Col span={24} >
                                            <NJVLabel label={i18next.t("receiver_name")} />
                                            <NJVValue value={`${order_object?.receiverName}`} />
                                        </Col>
                                        <Col span={24}>
                                            <NJVLabel label={i18next.t("receiver_phone_number")} />
                                            <NJVValue value={order_object?.receiverPhoneNumber} />
                                        </Col>
                                        <Col span={24}>
                                            <NJVLabel label={i18next.t("receiver_address")} />
                                            <NJVValue value={`${order_object?.addressDetail}, ${order_object?.receiverTownship?.label}, ${order_object?.receiverDivision?.label}`} />
                                        </Col>
                                        <Col span={24} >
                                            <NJVLabel label={i18next.t("delivery_fees")} />
                                            <NJVValue value={order_object?.deliveryFees} />
                                        </Col>
                                    </Row>
                                </NJVCard>
                            </Col>
                            <Col span={24} style={{ paddingLeft: '8px', paddingRight: '8px', textAlign: 'center' }}>
                                <NJVButton type="primary" size='large' style={{ width: 150, marginTop: "20" }} onClick={() => this.confirm()} text="Create Order" />
                            </Col>
                            {
                                orderList && orderList.length !== 0 ?
                                    <>
                                        <Col span={24}>
                                            <ReactToPrint
                                                trigger={() => <Button type='primary' ref={this.printButtonRef} icon={<PrinterOutlined />} style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}>Print Air Way Bill</Button>}
                                                content={() => this.refComponent}
                                            />
                                        </Col>
                                        <Col span={24}>
                                            <div
                                                key={1}
                                                ref={(el) => this.refComponent = el}>
                                                <>
                                                    {
                                                        orderList.map((order, index) => {

                                                            let fromAddress = order.fromAddress
                                                            let toAddress = order.toAddress
                                                            let createdDate = ''
                                                            if (order && order.fromTownship) {
                                                                fromAddress = fromAddress + "," + order.fromTownship.name
                                                                if (order.fromTownship.division) {
                                                                    fromAddress = fromAddress + "," + order.fromTownship.division.name
                                                                }
                                                            }
                                                            if (order && order.toTownship) {
                                                                toAddress = toAddress + "," + order.toTownship.name
                                                                if (order.toTownship.division) {
                                                                    toAddress = toAddress + "," + order.toTownship.division.name
                                                                }
                                                            }
                                                            if (order.createdDate != null) {
                                                                const date = dayjs(order.createdDate, 'YYYY-MM-DD HH:mm A');
                                                                if (date) {
                                                                    createdDate = date.format('YYYY-MMM-DD HH:mm A')
                                                                }
                                                            }
                                                            return <>
                                                                <AirWayBillSlip
                                                                    key={index}
                                                                    senderName={order.senderName}
                                                                    senderPhone={order.senderPhoneNumber}
                                                                    senderAddress={order.additionalAddress}
                                                                    receiverName={order.recipientName}
                                                                    receiverPhone={order.recipientPhoneNumber}
                                                                    receiverAddress={toAddress}
                                                                    trackId={order.trackingNumber}
                                                                    createDate={createdDate}
                                                                    parcelAmount={order.parcelAmount}
                                                                    deliveryCharges={order.deliveryCharges}
                                                                    cod={order.codAmount}
                                                                    note={order.note}
                                                                    sortingCode={order.sortingCode}
                                                                    storeName={order?.shipper?.fullName}
                                                                />
                                                                <AirWayBillSlip
                                                                    key={index}
                                                                    senderName={order.senderName}
                                                                    senderPhone={order.senderPhoneNumber}
                                                                    senderAddress={order.additionalAddress}
                                                                    receiverName={order.recipientName}
                                                                    receiverPhone={order.recipientPhoneNumber}
                                                                    receiverAddress={toAddress}
                                                                    trackId={order.trackingNumber}
                                                                    createDate={createdDate}
                                                                    parcelAmount={order.parcelAmount}
                                                                    deliveryCharges={order.deliveryCharges}
                                                                    cod={order.codAmount}
                                                                    note={order.note}
                                                                    sortingCode={order.sortingCode}
                                                                    storeName={order?.shipper?.fullName}
                                                                />

                                                            </>



                                                        })
                                                    }
                                                </>
                                            </div>
                                        </Col>
                                    </>
                                    :
                                    <></>
                            }




                        </Row>

                    </Col>
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(orderConfirm);